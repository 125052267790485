import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import config from '../../config';
import {
  addMarketplaceEntities,
  getMarketplaceEntities,
  getTxById,
} from '../../ducks/marketplaceData.duck';
import {
  findEndBoundary,
  findNextBookingTimeBoundary,
  findNextBoundary,
  findStartBoundary,
  monthIdStringInTimeZone,
  nextMonthFn,
} from '../../util/dates';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import { drivelahApiPost, drivelahApiPut } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  ensureCurrentUser,
  updatedEntities,
} from '../../util/data';
import {
  BOOKING_REQUEST_CANCELLED,
  sendEventGeneral,
  USER_SEND_MESSAGE,
} from '../../util/emailNotify';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import { sendGAEvent } from '../../util/googleAnalytics';
import { cancelTransaction } from '../../util/lightrail';
import * as log from '../../util/log';
import {
  lockShuCar,
  sendNotification,
  sendShuCarPushConfig,
  startShuCar,
  stopShuCar,
  unlockShuCar,
} from '../../util/notification';
import { types as sdkTypes } from '../../util/sdkLoader';
import { sendTransactionMsgContainingPhoneNoOrEmailNoti } from '../../util/slackNotify';
import {
  calculateBookingDays,
  getReview1Transition,
  getReview2Transition,
  isNewAdminActionTransition,
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_DECLINE,
  TRANSITION_DEPOSIT_FAIL,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
  TRANSITION_WITHDRAW,
  txHasBeenAccepted,
  txIsEnquired,
  txIsInFirstReviewBy,
} from '../../util/transaction';
import {
  TRANSITION_ADDITIONAL_ACCEPT,
  TRANSITION_ADDITIONAL_CUSTOMER_CANCEL,
  TRANSITION_ADDITIONAL_DECLINE,
  TRANSITION_ADDITIONAL_PROVIDER_CANCEL,
  TRANSITION_ADDITIONAL_WITHDRAW,
} from '../../util/transactionAddons';
import {
  getReview1TransitionLTL,
  getReview2TransitionLTL,
  PROCESS_NAME_LTR_LAST,
  txIsInFirstReviewByLTL,
} from '../../util/transactionLongTermLast';
import {
  doesMessageContainPhoneNumberOrEmail,
  encodeMsgContainingPhoneNoOrEmailMaybe,
} from '../../util/validators';
import { entityRefs, sortedTransactions } from '../InboxPage/InboxPage.duck';
import { getTransactionPhoneNumbers } from './TransactionPage.helper';
import {
  bookingProcessAliasFuelCharging,
  LINE_ITEM_FUEL_CHARGING_DRIVELAH_COMMISSIONS,
  LINE_ITEM_FUEL_CHARGING_PROVIDER_COMMISSIONS,
  TRANSITION_FUEL_CHARGING_CONFIRM_PAYMENT,
  TRANSITION_FUEL_CHARGING_REQUEST,
} from '../FuelCharging/fuelChargingHelpers';
import * as editTripApi from '../../containers/EditTripPage/EditTip.api';
import { updateParentTransaction } from '../../ducks/Tansactions.duck';
import { fetchUpdateBookingTxs } from '../EditTripPage/EditTripPage.duck';
import { countDistanceTwoPoints } from '../../util/maps';
import {
  bookingProcessAliasDistanceCharging,
  CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED,
  TRANSITION_DISTANCE_CHARGING_CONFIRM_PAYMENT,
  TRANSITION_DISTANCE_CHARGING_DECLINE,
  TRANSITION_DISTANCE_CHARGING_REQUEST_PAYMENT,
} from '../DistanceCharging/distanceChargingHelpers';
import Axios from 'axios';
import { failedDistanceTransitionHelper } from '../../components/TripPanel/transitionHelper';
import { pickUpDropOffByAdmin } from "../../modules/admin-actions/update-booking/adminUpdateBooking.duck";
import { showTransaction } from '../TripDetailsPage/TripDetailsPage.duck';

const apiUrl = config.apiUrl;
const { UUID, Money } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST';
export const FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS';
export const FETCH_UPDATE_BOOKING_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_UPDATE_BOOKING_TRANSACTION_ERROR';


export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const TRANSIT_REQUEST = 'app/TransactionPage/TRANSIT_REQUEST';
export const TRANSIT_SUCCESS = 'app/TransactionPage/TRANSIT_SUCCESS';
export const TRANSIT_ERROR = 'app/TransactionPage/TRANSIT_ERROR';

export const FETCH_ACCEPTED_BOOKING_TX_SUCCESS =
  'app/ListingPage/FETCH_ACCEPTED_BOOKING_TX_SUCCESS';
export const FETCH_ACCEPTED_BOOKING_TX_ERROR = 'app/ListingPage/FETCH_ACCEPTED_BOOKING_TX_ERROR';

export const FETCH_TX_PHONE_NUMBER_REQUEST = 'app/TransactionPage/FETCH_TX_PHONE_NUMBER_REQUEST';
export const FETCH_TX_PHONE_NUMBER_SUCCESS = 'app/TransactionPage/FETCH_TX_PHONE_NUMBER_SUCCESS';
export const FETCH_TX_PHONE_NUMBER_FAIL = 'app/TransactionPage/FETCH_TX_PHONE_NUMBER_FAIL';

export const ADD_CHILD_LONG_TERM_TRANSACTIONS =
  'app/TransactionPage/ADD_CHILD_LONG_TERM_TRANSACTIONS';
export const ADD_NEXT_CHILD_TRANSACTION = 'app/TransactionPage/ADD_NEXT_CHILD_TRANSACTION';
export const ADD_CURRENT_CHILD_TRANSACTION = 'app/TransactionPage/ADD_CURRENT_CHILD_TRANSACTION';

export const UPLOAD_INTERIOR_PHOTO_REQUEST =
  'app/TransactionPage/UPLOAD_INTERIOR_PHOTO_REQUEST';
export const UPLOAD_INTERIOR_PHOTO_SUCCESS = 'app/TransactionPage/UPLOAD_INTERIOR_PHOTO_SUCCESS';
export const UPLOAD_INTERIOR_PHOTO_ERROR = 'app/TransactionPage/UPLOAD_INTERIOR_PHOTO_ERROR';

export const CUSTOMER_UPDATE_BOOKING_REQUEST = 'app/TransactionPage/CUSTOMER_UPDATE_BOOKING_REQUEST';
export const CUSTOMER_UPDATE_BOOKING_SUCCESS = 'app/TransactionPage/CUSTOMER_UPDATE_BOOKING_SUCCESS';
export const CUSTOMER_UPDATE_BOOKING_ERROR = 'app/TransactionPage/CUSTOMER_UPDATE_BOOKING_ERROR';

export const UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST = 'app/TransactionPage/UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST';
export const UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS = 'app/TransactionPage/UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS';
export const UPDATE_BOOKING_BEFORE_PICK_UP_ERROR = 'app/TransactionPage/UPDATE_BOOKING_BEFORE_PICK_UP_ERROR';

export const UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST = 'app/TransactionPage/UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST';
export const UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS = 'app/TransactionPage/UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS';
export const UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR = 'app/TransactionPage/UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR';

export const ADD_LISTING_FOR_MAP = 'app/TransactionPage/ADD_LISTING_FOR_MAP';

export const GET_USER_DISTANCE_FROM_PICK_UP = 'app/TransactionPage/GET_USER_DISTANCE_FROM_PICK_UP';

export const GET_USER_LOCATION = 'app/StaticGoogleMap/GET_USER_LOCATION'

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,

  fetchUpdateBookingTransactionInProgress: false,
  fetchUpdateBookingTransactionError: null,
  transactionUpdateBookingRef: null,

  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitInProgress: false,
  transitError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  nextLongTermTransaction: null,
  childLongTermTransactions: [],
  currentChildLongTermTransaction: null,
  uploadInteriorPhotoProgress: false,
  uploadInteriorSuccess: false,
  uploadInteriorError: false,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  customerUpdateBookingSuccess: false,
  customerUpdateBookingInProgress: false,
  customerUpdateBookingError: null,

  updateBookingBeforePickUpSuccess: false,
  updateBookingBeforePickUpInProgress: false,
  updateBookingBeforePickUpError: null,

  updateBookingBeforeDropOffSuccess: false,
  updateBookingBeforeDropOffInProgress: false,
  updateBookingBeforeDropOffError: null,

  listingForMap: null,
  getDistanceFromPickUp: {
    distance: null,
    withinRange: null,
    locationPermission: null,
    error: "listing_distance_calculating"
  },

  userLocation: {
    lat: null,
    lng: null
  }
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };


    case FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST:
      return { ...state, fetchUpdateBookingTransactionInProgress: true, fetchUpdateBookingTransactionError: null };
    case FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchUpdateBookingTransactionInProgress: false, transactionUpdateBookingRef: transactionRef };
    }
    case FETCH_UPDATE_BOOKING_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchUpdateBookingTransactionInProgress: false, fetchUpdateBookingTransactionError: payload };


    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_ACCEPTED_BOOKING_TX_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchAcceptedBookingTxError: false,
        transactionRefs: entityRefs(transactions),
      };
    }
    case FETCH_ACCEPTED_BOOKING_TX_ERROR: {
      return {
        ...state,
        fetchAcceptedBookingTxError: true,
      };
    }
    case FETCH_ACCEPTED_BOOKING_TX_ERROR:
      return {
        ...state,
      };
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
    }

    case TRANSIT_REQUEST:
      return { ...state, transitInProgress: true };
    case TRANSIT_SUCCESS:
      return { ...state, transitInProgress: false };
    case TRANSIT_ERROR:
      return { ...state, transitInProgress: false, transitError: payload };
    case ADD_CHILD_LONG_TERM_TRANSACTIONS:
      return { ...state, childLongTermTransactions: payload };
    case ADD_NEXT_CHILD_TRANSACTION:
      return { ...state, nextLongTermTransaction: payload };
    case ADD_CURRENT_CHILD_TRANSACTION:
      return { ...state, currentChildLongTermTransaction: payload };
    case UPLOAD_INTERIOR_PHOTO_REQUEST:
      return { ...state, uploadInteriorPhotoProgress: true};
    case UPLOAD_INTERIOR_PHOTO_SUCCESS:
      return { ...state, uploadInteriorSuccess: true, uploadInteriorPhotoProgress: false};
    case UPLOAD_INTERIOR_PHOTO_ERROR:
      return { ...state, uploadInteriorError: true, uploadInteriorPhotoProgress: false}

    case CUSTOMER_UPDATE_BOOKING_REQUEST:
      return { ...state, customerUpdateBookingInProgress: true, customerUpdateBookingSuccess: false, customerUpdateBookingError: null };
    case CUSTOMER_UPDATE_BOOKING_SUCCESS:
      return { ...state, customerUpdateBookingInProgress: false, customerUpdateBookingSuccess: true, customerUpdateBookingError: false };
    case CUSTOMER_UPDATE_BOOKING_ERROR:
      return { ...state, customerUpdateBookingInProgress: false, customerUpdateBookingSuccess: false, customerUpdateBookingError: payload };

    case UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST:
      return { ...state, updateBookingBeforePickUpInProgress: true, updateBookingBeforePickUpSuccess: false, updateBookingBeforePickUpError: null };
    case UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS:
      return { ...state, updateBookingBeforePickUpInProgress: false, updateBookingBeforePickUpSuccess: true, updateBookingBeforePickUpError: false };
    case UPDATE_BOOKING_BEFORE_PICK_UP_ERROR:
      return { ...state, updateBookingBeforePickUpInProgress: false, updateBookingBeforePickUpSuccess: false, updateBookingBeforePickUpError: payload };

    case UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST:
      return { ...state, updateBookingBeforeDropOffInProgress: true, updateBookingBeforeDropOffSuccess: false, updateBookingBeforeDropOffError: null };
    case UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS:
      return { ...state, updateBookingBeforeDropOffInProgress: false, updateBookingBeforeDropOffSuccess: true, updateBookingBeforeDropOffError: false };
    case UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR:
      return { ...state, updateBookingBeforeDropOffInProgress: false, updateBookingBeforeDropOffSuccess: false, updateBookingBeforeDropOffError: payload };

    case ADD_LISTING_FOR_MAP:
      return { ...state, listingForMap: payload}

    case GET_USER_DISTANCE_FROM_PICK_UP:
      return {
        ...state,
        distanceFromPickUp: payload,
      };

    case GET_USER_LOCATION:
      return {
        ...state,
        userLocation: payload
      }

    default:
      return state;
  }
}

export const setDistanceFromPickUp = data => ({
  type: GET_USER_DISTANCE_FROM_PICK_UP,
  payload: data
});

export const setUserLocation = data => ({
  type: GET_USER_LOCATION,
  payload: data
})

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

export const requestToUpdateBookingInProgress = state => {
  return (
    state.TransactionPage.customerAcceptInProgress ||
    state.TransactionPage.customerDeclineInProgress
  );
};

export const transitInProgress = state => {
  return (
    state.TransactionPage.acceptInProgress ||
    state.TransactionPage.declineInProgress ||
    state.TransactionPage.transitInProgress
  );
};

const $transactionRef = state => {
  return state.TransactionPage.transactionRef;
};

export const $bookingTx = state => {
  const txRef = $transactionRef(state);
  return txRef ? getTxById(state, txRef.id.uuid) : null;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_UPDATE_BOOKING_TRANSACTION_ERROR, error: true, payload: e });

const fetchUpdateBookingTransactionRequest = () => ({ type: FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST });
const fetchUpdateBookingTransactionSuccess = response => ({
  type: FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchUpdateBookingTransactionError = e => ({ type: FETCH_UPDATE_BOOKING_TRANSACTION_ERROR, error: true, payload: e });



const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

const transitRequest = () => ({ type: TRANSIT_REQUEST });
const transitSuccess = () => ({
  type: TRANSIT_SUCCESS,
});
const transitError = e => ({
  type: TRANSIT_ERROR,
  error: true,
  payload: e,
});

export const fetchAcceptedBookingTxSuccess = response => ({
  type: FETCH_ACCEPTED_BOOKING_TX_SUCCESS,
  payload: response,
});

export const fetchAcceptedBookingTxError = error => ({
  type: FETCH_ACCEPTED_BOOKING_TX_ERROR,
  payload: error,
});

const addChildLongTermTransactions = childLongTermTransactions => ({
  type: ADD_CHILD_LONG_TERM_TRANSACTIONS,
  payload: childLongTermTransactions,
});

const addNextLongTermTransaction = nextLongTermTransaction => ({
  type: ADD_NEXT_CHILD_TRANSACTION,
  payload: nextLongTermTransaction,
});

const addCurrentChildLongTermTransaction = currentChildLongTermTransaction => ({
  type: ADD_CURRENT_CHILD_TRANSACTION,
  payload: currentChildLongTermTransaction,
});

const addListingForMap = listing => ({
  type: ADD_LISTING_FOR_MAP,
  payload: listing
})

const uploadInteriorPhotoRequest = () => ({ type: UPLOAD_INTERIOR_PHOTO_REQUEST})
const uploadInteriorPhotoSuccess = () => ({ type: UPLOAD_INTERIOR_PHOTO_SUCCESS});
const uploadInteriorPhotoError = () => ({ type: UPLOAD_INTERIOR_PHOTO_ERROR})

const customerUpdateBookingRequest = () => ({ type: CUSTOMER_UPDATE_BOOKING_REQUEST });
const customerUpdateBookingSuccess = () => ({ type: CUSTOMER_UPDATE_BOOKING_SUCCESS });
const customerUpdateBookingError = e => ({ type: CUSTOMER_UPDATE_BOOKING_ERROR, error: true, payload: e });

const updateBookingBeforePickUpRequest = () => ({ type: UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST });
const updateBookingBeforePickUpSuccess = () => ({ type: UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS });
const updateBookingBeforePickUpError = e => ({ type: UPDATE_BOOKING_BEFORE_PICK_UP_ERROR, error: true, payload: e });

const updateBookingBeforeDropOffRequest = () => ({ type: UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST });
const updateBookingBeforeDropOffSuccess = () => ({ type: UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS });
const updateBookingBeforeDropOffError = e => ({ type: UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR, error: true, payload: e });

export const getDistanceFromPickUp = listingLocation => dispatch => {
  if (!(listingLocation && listingLocation.lat)) {
    return dispatch(setDistanceFromPickUp({
      distance: null,
      error: 'no_listing_location'
    }));
  }
  let watchId;
  const handleSuccess = position => {
    console.log("================locationPermissionSuccess", position)
    if (position && position.coords) {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      dispatch(setUserLocation({
        lat,
        lng
      }))
      console.log('listingLocation.lat', listingLocation.lat)
      console.log('listingLocation.lng', listingLocation.lng)
      console.log('listingLocation.lnglat, lng',lat, lng)
      const distance = countDistanceTwoPoints(listingLocation.lat, listingLocation.lng, lat, lng);
      console.log('distance came', distance)
      if (distance * 1000 <= 250) {
        return dispatch(setDistanceFromPickUp({
          distance: distance * 1000,
          withinRange: true
        }));
      }
      return dispatch(setDistanceFromPickUp({
        distance: distance * 1000,
        withinRange: false
      }));
    }
  };
  const handleError = error => {
    console.log("================locationPermissionError", error)
    if(error.code == 1) {
      console.log("user permission denied")
    }
    return dispatch(setDistanceFromPickUp({
      distance: null,
      locationPermission: 'denied'
    }));
  };

  watchId = navigator.geolocation.watchPosition(handleSuccess, handleError);
  return () => {
    navigator.geolocation.clearWatch(watchId);
  };
};


// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

// const getSmsData = denormalised => {
//   const { displayName: customerName } = denormalised.customer.attributes.profile;
//   const { displayName: providerName } = denormalised.provider.attributes.profile;
//   const { title: listingTitle } = denormalised.listing.attributes;
//   const {
//     bookingDisplayStart,
//     bookingDisplayEnd,
//     customerPhoneNumberObj,
//   } = denormalised.attributes.protectedData;
//   const pickupDateTime = moment(bookingDisplayStart).format('DD/MM/YYYY hh:mm a');
//   const dropoffDateTime = moment(bookingDisplayEnd).format('DD/MM/YYYY hh:mm a');
//   return {
//     customerName,
//     providerName,
//     listingTitle,
//     pickupDateTime,
//     dropoffDateTime,
//     customerPhoneNumber: `${customerPhoneNumberObj.phoneCode}${customerPhoneNumberObj.rawNumber}`,
//   };
// };

const sendTransitEmailToAdmin = (id, transition, transaction = null, currentUser = null) => {
  const { start, end } =
  (transaction && transaction.booking && transaction.booking.attributes) || {};
  const bookingDays = calculateBookingDays(start, end);

  switch (transition) {
    case TRANSITION_CANCEL:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
      sendEventGeneral({ eventType: BOOKING_REQUEST_CANCELLED, transactionUUID: id.uuid });
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Trip Cancelled!',
        eventLabel: bookingDays,
      });
    // const smsData = getSmsData(transaction);
    // return sendEventGeneral({
    //   eventType: BOOKING_REQUEST_CANCELLED,
    //   transactionUUID: id.uuid,
    //   transition,
    //   ...smsData,
    // });
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
      sendEventGeneral({ eventType: BOOKING_REQUEST_CANCELLED, transactionUUID: id.uuid });
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Trip Cancelled!',
        eventLabel: bookingDays,
      });
    // return sendEventGeneral({ eventType: BOOKING_REQUEST_CANCELLED, transactionUUID: id.uuid });
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Host Confirms Pickup',
      });
    // return sendEventGeneral({
    //   eventType: EMAIL_PICKUP_CONFIRMED,
    //   transactionUUID: id.uuid,
    //   listingName: transaction.listing.attributes.title,
    //   guestPhoneNumber: `${transaction.attributes.protectedData.customerPhoneNumberObj.phoneCode}${transaction.attributes.protectedData.customerPhoneNumberObj.rawNumber}`,
    //   hostName: transaction.provider.attributes.profile.displayName,
    // });
    case TRANSITION_CONFIRM_DROP_OFF: {
      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Host Confirms Dropoff',
      });
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Complete A Trip Successfully',
        eventValue: 4,
        eventLabel: bookingDays,
      });
      // return sendEventGeneral({
      //   eventType: EMAIL_DROPOFF_CONFIRMED,
      //   transactionUUID: id.uuid,
      //   listingName: transaction.listing.attributes.title,
      //   guestPhoneNumber: `${transaction.attributes.protectedData.customerPhoneNumberObj.phoneCode}${transaction.attributes.protectedData.customerPhoneNumberObj.rawNumber}`,
      //   hostName: transaction.provider.attributes.profile.displayName,
      // });
    }
    case TRANSITION_REQUEST_DROP_OFF:
    // const { provider, listing } = transaction;
    // return sendEventGeneral({
    //   eventType: EMAIL_DROPOFF_REQUEST,
    //   transactionUUID: id.uuid,
    //   listingName: listing.attributes.title,
    //   listingId: listing.id.uuid,
    //   guestName: currentUser.attributes.profile.displayName,
    //   guestId: currentUser.id.uuid,
    //   hostName: provider.attributes.profile.displayName,
    //   hostId: provider.id.uuid,
    //   guestReferralCodeData: currentUser.attributes.profile.metadata.guestReferralCodeData,
    // });
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    // return sendEventGeneral({
    //   eventType: EMAIL_PICKUP_REQUEST,
    //   transactionUUID: id.uuid,
    //   listingName: transaction.listing.attributes.title,
    //   guestName: transaction.customer.attributes.profile.displayName,
    // });
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    // return sendGAEvent({
    //   eventCategory: 'Transaction',
    //   eventAction: 'A Trip Withdrawn',
    //   eventLabel: bookingDays,
    // });
    default:
      return;
  }
};

const updateTransaction = (metadata, id, dispatch) => {
  const { pickupSentAt, dropoffSentAt, pickupFrom, dropoffFrom } = metadata
  if (pickupSentAt && pickupFrom) {
    dispatch(updateParentTransaction(id.uuid, { pickupSentAt: pickupSentAt, pickupFrom: pickupFrom }));
  }
  if (dropoffSentAt && dropoffFrom) {
    dispatch(updateParentTransaction(id.uuid, { dropoffSentAt: dropoffSentAt, dropoffFrom: dropoffFrom }));
  }
}

export const createIntercomTicket = async ({data = {}, ticketExists = false}) => {
  if (ticketExists) return;
  try {
    const res = await Axios.post(
      `${process.env.REACT_APP_API_SERVER_URL}/api/tickets/create-ticket`,
      data,
    );
    console.log('Intercom ticket created successfully >>', res.data);
    return res.data;
  } catch (error) {
    console.log('Error occured while creating intercom ticket');
  }
}

export const updateIntercomTicket = async ({id, data}) => {
  try {
    const res = await Axios.post(
      `${process.env.REACT_APP_API_SERVER_URL}/api/tickets/update-ticket/${id}`,
      data,
    );
    console.log('Intercom ticket updated successfully >>', res.data);

    return res.data;
  } catch (error) {
    console.log('Error occured while updating intercom ticket');
  }
}

export const getOdometerReadings = (data) => {
  const odometerReadings = {
      pickUp: null,
      dropOff: null
  };

  console.log('Handle DropOff Button Click >> Transit Called >> odoMeterReadings >> data', data );

  // Flatten the input array and filter for odometerPhotos
  const allEntries = data.flat().filter(({ odometerPhotos }) => odometerPhotos);

  console.log('Handle DropOff Button Click >> Transit Called >> odoMeterReadings >> allEntries', allEntries );

  allEntries.forEach(({ isPickUp, note }) => {
      if (isPickUp) {
          odometerReadings.pickUp = note;
      } else {
          odometerReadings.dropOff = note;
      }
  });

  console.log('Handle DropOff Button Click >> Transit Called >> return == odoMeterReadings >> odometerReadings', odometerReadings );

  return odometerReadings;
};

const getFuelReciptsArray = data => {
  return data
    .flat()
    .filter(item => item.fuelReceiptPhotos)
    .map(item => item.fileUrl);
};

const getFuelReciptsSummary = data => {
  return data
    .flat()
    .filter(item => item.fuelReceiptPhotos)
    .reduce(
      (summary, item) => {
        summary.totalFuelAmount += parseFloat(item.fuelAmount) || 0;
        summary.totalFuelQuantity += parseFloat(item.fuelQuantity) || 0;
        return summary;
      },
      { totalFuelAmount: 0, totalFuelQuantity: 0 });
};

export const transit = (
  id,
  transition,
  dataTransit = {},
  photoObjects = null,
  certificate = null,
  isShuCar = null,
  isStartCar = null,
  isLockCar = null,
  isUnlockCar = null,
  isLocateCar = null,
  isDlGoV3 = null,
  isStopCar = null,
  cleanlinessScore = null,
  isDropOff = null
) => async (dispatch, getState, sdk) => {
  console.log("Transit called", isDropOff, dataTransit, transition);
  console.log('Handle DropOff Button Click >> Transit Called >>', {id, isDropOff, photoObjects, dataTransit, transition});
  if (transitInProgress(getState()) && transition !== TRANSITION_DEPOSIT_FAIL) {
    return Promise.reject(new Error('A pending transit already in progress'));
  }

  console.log("Cancel transition", transition, isDropOff);

  dispatch(transitRequest());

  const { cancelReason, cancelType, pickUpOdometer, dropOffOdometer, currentTransaction, pickupSentAt, dropoffSentAt, pickupFrom, dropoffFrom } =
    dataTransit || {};
  const state = getState();
  const currentUser = state.user.currentUser;
  const metadata = {}

  if (pickupSentAt && pickupFrom) {
    metadata.pickupSentAt = pickupSentAt;
    metadata.pickupFrom = pickupFrom;
  }
  if (dropoffSentAt && dropoffFrom) {
    metadata.dropoffSentAt = dropoffSentAt;
    metadata.dropoffFrom = dropoffFrom;
  }


  const protectedData = {};
  if (cancelReason) {
    protectedData.cancelReason = cancelReason;
  }
  if (photoObjects) {
    protectedData.photoObjects = photoObjects;
  }
  if (pickUpOdometer) {
    protectedData.pickUpOdometer = pickUpOdometer;
  } else if (dropOffOdometer) {
    if (currentTransaction) {
      await handlePaymentFuel({ currentTransaction, dropOffOdometer, currentUser, sdk });
    }
    protectedData.dropOffOdometer = dropOffOdometer;
  }

  if(cleanlinessScore) {
    protectedData.cleanlinessScore = cleanlinessScore;
  }
  const bodyParams = { protectedData } ;
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];
  const additionalTransactions = transaction.attributes.metadata.addonsTransactions;
  console.log("Adding additional transactions", cancelType);
  const particularParams = !cancelType
    ? {}
    : {
        transitionType: 'cancel',
        isCommonTransition: true,
        transaction: currentTransaction || transaction,
      };

  const apiData = {
    user_id: transaction.customer.attributes.profile.publicData.shuUserId,
    booking_id: id.uuid,
    fleet_id: transaction.listing.attributes.publicData.license_plate_number,
    listingId: transaction.listing.id.uuid
  };
  if (isShuCar) {
    sendShuCarPushConfig(apiData);
    if(isStartCar) {
      await new Promise(r => setTimeout(r, 10000));
    }
  }

  if (isStartCar) {
    startShuCar(apiData);
  }
  if (isStopCar) {
    stopShuCar(apiData);
  }
  if (isLockCar) {
    lockShuCar(apiData);
    dispatch(transitSuccess());
    return;
  }
  if (isUnlockCar) {
    unlockShuCar(apiData);
    dispatch(transitSuccess());
    return;
  }

  if (!cancelType) {
    console.log('Handle DropOff Button Click >> Transit Called >> Semi - Final transition >>', transition);

    // Getting the readings of odometer, booking end timestamp, timezone to pass in fetchDistanceTravelled API
    const odometerReadings = getOdometerReadings(bodyParams.protectedData.photoObjects);
    console.log('Handle DropOff Button Click >> Transit Called >> odoMeterReadings', odometerReadings);

    // Getting booking end timestamp, timezone to pass in fetchDistanceTravelled API
    const transactionTimezone = get(transaction, 'attributes.protectedData.transactionTimezone', '');
    const tripEndTime = get(transaction, 'booking.attributes.displayEnd', '');
    console.log('Handle DropOff Button Click >> Transit Called >> timeZone, tripEndTime >>', {
      transactionTimezone,
      tripEndTime
    });

    // Checking if the transaction is distance charging transaction or not
    const isDistanceCharge = get(transaction, 'attributes.protectedData.isDistanceCharge', false);

    // Attempting to chage for distance when drop off is happening and transaction is distance charging transaction.
    if (!isNewAdminActionTransition(transition)) {
      try {
        if (isDropOff && isDistanceCharge) {
          // Parameters needed for getting distance
          const params = { odometerReadings };

          // Fetching distance travelled
          const distanceTravelled = await fetchingDistanceTravelled(id, params, dispatch);

          // If distance travelled is there, attempt to create transaction for distance
          if (distanceTravelled) {
            const distanceTransaction = await createDistanceTransaction({ id, currentTransaction, distanceTravelled, transactionTimezone, tripEndTime, isDropOff, dropOffOdometer, currentUser, sdk, dispatch });

            // If distance transaction is created successfully, attempt to charge for that distance transaction
            if (distanceTransaction.id.uuid) {
              const chargingForDistance = await chargingForDistanceTransaction({ id, currentTransaction, distanceTravelled, transactionTimezone, tripEndTime, isDropOff,distanceTransaction, dropOffOdometer, currentUser, sdk, dispatch });

              console.log('Response after distance charging successful >', chargingForDistance);
            }
          }
        }
      } catch (error) {
        console.log('Error Occured while charging for distance >', error);

        // Changing the incomming transition to distance failed transition if payment for distance fails.
        const failedTransition = failedDistanceTransitionHelper(transition);
        transition = failedTransition;
      }
    }
    
    if (isNewAdminActionTransition(transition) && isDropOff && isDistanceCharge) { 
      try {
        // Updating distance charging failed in parent transaction
        dispatch(updateParentTransaction(id.uuid, { isDistanceCharged: false }));

        // Calling the admin drop off api here.
        const res = Axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/handle-distance-charge-failed`,
          {
            transactionId: get(transaction, 'id.uuid', ''),
            dropOffOdometer: odometerReadings.dropOff,
            dropoffSentAt: dropoffSentAt,
          },
        );
        
      } catch (error) {
        console.log('Error Occured in api call for admin drop off >', error);
      }
    }
  }
  console.log('Handle DropOff Button Click >> Transit Called >> Final transition >>', transition);

return sdk.processTransitions
  .query({ transactionId: id && id.uuid })
  .then(response => {
    const possibleTransitions = response.data.data;
    console.log("Possible transitions: ", possibleTransitions);
    console.log("Coming transition", transition);
    possibleTransitions.map(t => console.log("Transition", t.attributes.name));
    if (!possibleTransitions.find(t => t.attributes.name === transition)) {
      alert('Your transaction page is outdated, please reload the page and try again');
      return window.location.reload();
    }
    console.log("Your transaction page is", { id, particularParams, transition: transition, params: bodyParams });
    const confirmParams = {
      id,
      transition: transition,
      params: bodyParams
    };
    console.log("Confirm transaction params", confirmParams);
    if (isNewAdminActionTransition(transition)) {
      console.log('came here api')
      return pickUpDropOffByAdmin(id.uuid, transition, bodyParams)
        .then(result => {
          console.log('Admin action response data:', result);
          return result;
        })
        .catch(error => {
          console.error('Error during admin action:', error);
          throw error;
        });
    }
    return sdk.jh.transactions.transition(
      { id, particularParams, transition: transition, params: bodyParams },
      { expand: true }
    );
  })
  .then(async response => {
    await updateTransaction(metadata, id, dispatch)

    // await sendNotification({
    //   userId: currentUser.id.uuid,
    //   transactionId: id,
    //   transition: transition,
    //   uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
    // });

    if (
      additionalTransactions &&
      additionalTransactions.length > 0 &&
      (transition === TRANSITION_WITHDRAW ||
        transition === TRANSITION_UNVERIFIED_WITHDRAW ||
        transition === TRANSITION_UNVERIFIED_WITHDRAW_INSTANT ||
        transition === TRANSITION_PROVIDER_CANCEL_REFUNDABLE ||
        transition === TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR ||
        transition === TRANSITION_CUSTOMER_CANCEL_REFUNDABLE)
    ) {
      let additionalTransition = TRANSITION_ADDITIONAL_WITHDRAW;

      if (
        transition === TRANSITION_PROVIDER_CANCEL_REFUNDABLE ||
        transition === TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR
      ) {
        additionalTransition = TRANSITION_ADDITIONAL_PROVIDER_CANCEL;
      }

      if (
        transition === TRANSITION_CUSTOMER_CANCEL_REFUNDABLE
      ) {
        additionalTransition = TRANSITION_ADDITIONAL_CUSTOMER_CANCEL;
      }
      for (let i = 0; i < additionalTransactions.length; i++) {
        const bodyParams = {
          id: additionalTransactions[i].transactionId,
          transition: additionalTransition,
          params: {},
        };

        await sdk.transactions.transition(bodyParams, { expand: true });
      }
    }

    if (cancelReason) {
      return cancelTransaction(id.uuid)
        .then(() => {
          return response;
        })
        .catch(e => {
          return response;
        });
    } else {
      return response;
    }
  })
  .then(response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(transitSuccess());
    dispatch(fetchCurrentUserNotifications());
    sendTransitEmailToAdmin(id, transition, transaction, currentUser);

    return response;
  })
  .catch(e => {
    dispatch(transitError(storableError(e)));
    log.error(e, 'transit-failed', {
      txId: id,
      transition: transition,
    });
    throw e;
  })
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'customer.attributes.profile',
          // 'customer.attributes.protectedData',
          'customer.attributes.profile.protectedData',
          'customer.attributes.profile.publicData',
          'provider',
          'provider.profileImage',
          'provider.attributes.protectedData',
          'provider.attributes.profile.protectedData',
          'provider.attributes.profile.publicData',
          'listing',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(async response => {
      dispatch(fetchUpdateBookingTxs(response.data.data));
      const listingForMap = response.data.included && response.data.included.filter(i => i.type === 'listing')[0];

      dispatch(addListingForMap(listingForMap));

      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
      if (isLongTerm) {
        const { currentUser } = getState().user;
        const cachedUser = getCurrentUser();
        const userId = (cachedUser && cachedUser.id.uuid) || (currentUser && currentUser.id.uuid);
        const childTransactionIds = get(transaction, 'attributes.metadata.childTransactionIds');
        const getBunchTransactionURL = 'transactions/retrieve-bunch-transactions';
        const data = {
          userId,
          transactionIds: childTransactionIds,
        };
        const childTransactionsRes = await drivelahApiPost(getBunchTransactionURL, data);
        const transactionParent = get(txResponse, 'data.data');
        const childTransactions = get(childTransactionsRes, 'data.data');
        dispatch(addChildLongTermTransactions(childTransactions));
        const nextTransactionId = get(transactionParent, 'attributes.metadata.nextTransaction');
        const currentChildTransactionId = get(
          transactionParent,
          'attributes.metadata.currentChildTransaction'
        );
        const nextTransaction = childTransactions.find(
          child => child.id.uuid === nextTransactionId
        );
        const currentChildTransaction = childTransactions.find(
          child => child.id.uuid === currentChildTransactionId
        );
        dispatch(addNextLongTermTransaction(nextTransaction));
        dispatch(addCurrentChildLongTermTransaction(currentChildTransaction));
      }
      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      fetchMonthlyTimeSlots(dispatch, listing);

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        if (txRole === 'customer') {
          return sdk.listings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        } else {
          return sdk.ownListings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        }
      } else {
        return response;
      }
    })
    .then(response => {
      const finalTxResponse = txResponse;
      if (
        finalTxResponse &&
        finalTxResponse.data &&
        finalTxResponse.data.data &&
        finalTxResponse.data.data.attributes &&
        finalTxResponse.data.data.attributes.protectedData &&
        (!finalTxResponse.data.data.attributes.protectedData.hostPhoneNumber ||
         !finalTxResponse.data.data.attributes.protectedData.customerPhoneNumber)
      ) {
        dispatch(
          fetchTransactionPhoneNumber({
            transactionId: id.uuid,
            response,
            finalTxResponse,
          })
        );
      }
      dispatch(addMarketplaceEntities(finalTxResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(finalTxResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionPhoneNumber = ({ transactionId, finalTxResponse }) => dispatch => {
  dispatch({ type: FETCH_TX_PHONE_NUMBER_REQUEST });
  return getTransactionPhoneNumbers(transactionId)
    .then(response => {
      if (response.status !== 200) {
        return dispatch({
          type: FETCH_TX_PHONE_NUMBER_FAIL,
          payload: response.json(),
        });
      }
      return response.json();
    })
    .then(data => {
      const { customerPhoneNumberObj, hostPhoneNumberObj } = data;

      finalTxResponse.data.data.attributes.protectedData = {
        ...finalTxResponse.data.data.attributes.protectedData,
        customerPhoneNumber:customerPhoneNumberObj,
        hostPhoneNumber:hostPhoneNumberObj,
      };

      dispatch(addMarketplaceEntities(finalTxResponse));

      return dispatch({
        type: FETCH_TX_PHONE_NUMBER_SUCCESS,
        payload: {
          customerPhoneNumberObj,
          hostPhoneNumberObj,
        },
      });
    })
    .catch(error => {
      return dispatch({
        type: FETCH_TX_PHONE_NUMBER_FAIL,
        payload: error,
      });
    });
};

export const requestToUpdateBooking = (params) => (dispatch, getState, sdk) => {
  const {
    transactionId,
    listing,
    bookingStart,
    bookingEnd,
    transition,
    protectedData,
  } = params

  if (requestToUpdateBookingInProgress(getState())) {
    return Promise.reject(new Error('Update booking already in progress'));
  }

  dispatch(customerUpdateBookingRequest());

  const { currentUser } = getState().user;

  const transactionParams = protectedData ?
    {
      id: transactionId,
      transition,
      params: {
        listingId: listing.id,
        bookingStart,
        bookingEnd,
        protectedData
      },
    } : {
      id: transactionId,
      transition,
      params: {
        listingId: listing.id,
        bookingStart,
        bookingEnd,
      },
    };


  return sdk.transactions
    .transition(transactionParams)
    .then(async response => {
      dispatch(customerUpdateBookingSuccess());

      // sendNotification({
      //   userId: currentUser.id.uuid,
      //   transactionId: transactionId,
      //   transition: transition,
      //   uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      // });

      dispatch(fetchTransaction(transactionId, 'customer'));
      if(protectedData && (transition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
        transition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
        transition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
        transition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF) ) {
        if (params.protectedData.childTransaction.length) {
          dispatch(fetchAcceptDeclineUpdateBooking({
            transactionId: params.protectedData.childTransaction.transactionId,
            transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT
          }));
        }

        // sendNotification({
        //   userId: currentUser.id.uuid,
        //   transactionId: transactionId,
        //   transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
        //   uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
        // });
      }

      if(protectedData && (transition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
         transition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE ||
         transition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
         transition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
        transition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)) {
        if (params.protectedData.childTransaction.length) {
          dispatch(fetchAcceptDeclineUpdateBooking({
            transactionId: params.protectedData.childTransaction.transactionId,
            transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE
          }))
        }

        sendNotification({
          userId: currentUser.id.uuid,
          transactionId: transactionId,
          transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
          uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
        });
      }

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());

      await sendNotification({
        userId: currentUser.id.uuid,
        transactionId: transactionId,
        transition: TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      return response;
    })
    .catch(e => {
      dispatch(customerUpdateBookingError(storableError(e)));
      throw e;
    });
};

export const fetchAcceptDeclineUpdateBooking = param => (dispatch, getState, sdk) => {
  const { transactionId, transition } = param;

  if(transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT){
    dispatch(acceptSaleRequest());
  }
  if(transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE) {
    dispatch(declineSaleRequest());
  }

  return sdk.jh.transactions
    .transition(
      {
        processAlias: config.updateBookingChargingProcessAlias,
        id: transactionId,
        transition,
        params: {},
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());

      return response;
    })
    .catch(e => {

      log.error(e, 'reject-sale-failed', {
        txId: transactionId,
        transition,
      });
      throw e;
    });
};

export const fetchCancelUpdateBooking = param => (dispatch, getState, sdk) => {
  const { transactionId, transition } = param;

  return sdk.jh.transactions
    .transition(
      {
        processAlias: config.updateBookingChargingProcessAlias,
        id: transactionId,
        transition,
        params: {},
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());

      return response;
    })
    .catch(e => {
      log.error(e, 'reject-sale-failed', {
        txId: transactionId,
        transition,
      });
      throw e;
    });
};

export const acceptSale = (id, userObj = null, processAlias) => async (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  const { currentUser } = getState().user;

  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];

  const { start, end } =
  (transaction && transaction.booking && transaction.booking.attributes) || {};
  const bookingDays = calculateBookingDays(start, end);
  const additionalTransactions = transaction.attributes.metadata.addonsTransactions;

  let transactionResponse = null;

  const particularParams = {
    transitionType: 'accept',
    isCommonTransition: true,
    transaction,
  };
  return sdk.jh.transactions
    .transition(
      { processAlias: processAlias ? processAlias : config.masterProcessAlias, id, particularParams, transition: TRANSITION_ACCEPT, params: {} },
      { expand: true }
    )
    .then(async response => {
      if (additionalTransactions && additionalTransactions.length > 0) {
        for (let i = 0; i < additionalTransactions.length; i++) {
          const bodyParams = {
            id: additionalTransactions[i].transactionId,
            transition: TRANSITION_ADDITIONAL_ACCEPT,
            params: {},
          };

          await sdk.transactions.transition(bodyParams, { expand: true })
        }
      }

      transactionResponse = response;
      return transactionResponse;
    })
    .then(promoResponse => {
      dispatch(addMarketplaceEntities(transactionResponse));
      dispatch(acceptSaleSuccess());

      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Accept Request',
        eventValue: 3,
        eventLabel: bookingDays,
      });

      dispatch(fetchCurrentUserNotifications());
      sendNotification({
        userId: currentUser.id.uuid,
        transactionId: id,
        transition: TRANSITION_ACCEPT,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      return transactionResponse;
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = (id, processAlias) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];
  const additionalTransactions = transaction.attributes.metadata.addonsTransactions;
  let transactionResponse = null;
  const particularParams = {
    transitionType: 'decline',
    isCommonTransition: true,
    transaction,
  };
  return sdk.jh.transactions
    .transition(
      { processAlias: processAlias ? processAlias : config.masterProcessAlias, id, transition: TRANSITION_DECLINE, particularParams, params: {} },
      { expand: true, include: ['customer', 'provider', 'listing'] }
    )
    .then(async response => {
      sendNotification({
        userId: currentUser.id.uuid,
        transactionId: id,
        transition: TRANSITION_DECLINE,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });
      if (additionalTransactions && additionalTransactions.length > 0) {
        for (let i = 0; i < additionalTransactions.length; i++) {
          const bodyParams = {
            id: additionalTransactions[i].transactionId,
            transition: TRANSITION_ADDITIONAL_DECLINE,
            params: {},
          };

          await sdk.transactions.transition(bodyParams, { expand: true })
        }
      }
      transactionResponse = response;
      return cancelTransaction(id.uuid);
    })
    .then(promoResponse => {
      dispatch(addMarketplaceEntities(transactionResponse));
      dispatch(declineSaleSuccess());
      dispatch(fetchCurrentUserNotifications());

      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Booking request declined!',
      });

      const listingId = listingRelationship(transactionResponse).id;
      const entities = updatedEntities({}, transactionResponse.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);

      // const smsData = getSmsData(denormalised[1]);
      // sendEventGeneral({
      //   eventType: BOOKING_REQUEST_DECLINED,
      //   transactionUUID: id.uuid,
      //   ...smsData,
      // });
      return transactionResponse;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());
  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];
  const { currentUser } = state.user;
  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;
  const targetId = isHost ? transaction.customer.id.uuid : transaction.provider.id.uuid;
  const senderName = currentUser.attributes.profile.displayName;

  if (!txHasBeenAccepted(transaction) && doesMessageContainPhoneNumberOrEmail(message)) {
    sendTransactionMsgContainingPhoneNoOrEmailNoti({
      transactionId: txId.uuid,
      customerName: transaction.customer.attributes.profile.displayName,
      providerName: transaction.provider.attributes.profile.displayName,
      senderRole: isHost ? 'provider' : 'customer',
      message,
    });
    message = encodeMsgContainingPhoneNoOrEmailMaybe(message);
  }

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // sendEventGeneral({
      //   eventType: USER_SEND_MESSAGE,
      //   transactionUUID: txId.uuid,
      //   senderName,
      //   targetId,
      //   isHost,
      //   message,
      // });
      sendNotification({
        userId: currentUser.id.uuid,
        transactionId: txId,
        transition: USER_SEND_MESSAGE,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (tx, params, role, dispatch, sdk) => {
  const { id } = tx;
  const processName = get(tx, 'attributes.processName');
  let transition = getReview2Transition(role === CUSTOMER);
  if (processName === PROCESS_NAME_LTR_LAST) {
    transition = getReview2TransitionLTL(role === CUSTOMER);
  }

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (tx, params, role, dispatch, sdk) => {
  const { id } = tx;
  const processName = get(tx, 'attributes.processName');
  let transition = getReview1Transition(role === CUSTOMER);
  if (processName === PROCESS_NAME_LTR_LAST) {
    transition = getReview1TransitionLTL(role === CUSTOMER);
  }
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(storableError(e))) {
        return sendReviewAsSecond(tx, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst =
    txIsInFirstReviewBy(tx, role !== CUSTOMER) || txIsInFirstReviewByLTL(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx, params, role, dispatch, sdk);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

const createParamsForFetchTimeSlots = (maxTimeSlots, bookingRange, listingId, tz) => {
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);
  let nextBoundary = findNextBookingTimeBoundary(tz, new Date());
  let nextMonth = null;
  let count = 0;
  const params = [];
  do {
    const numberOfSlot = Math.min(timeSlotsRange, bookingRange - timeSlotsRange * count);
    nextBoundary = findStartBoundary(nextMonth || nextBoundary, tz);
    nextMonth = findEndBoundary(nextBoundary, tz, numberOfSlot);
    params.push({
      start: nextBoundary,
      end: nextMonth,
      listingId,
    });
    count++;
  } while (timeSlotsRange * count < bookingRange);
  return params;
};

// Helper function for fetchTransaction call.
export const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const handlePaymentFuel = async ({
  card = null,
  currentTransaction,
  dropOffOdometer,
  currentUser,
  sdk,
}) => {
  const pickUpOdometer = get(currentTransaction, 'attributes.protectedData.pickUpOdometer', 0);
  const currentTransactionId = get(currentTransaction, 'id.uuid');
  const listingId = get(currentTransaction, 'listing.id.uuid');
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserId = ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
  const paymentMethod = get(
    ensuredCurrentUser,
    'stripeCustomer.defaultPaymentMethod.attributes.stripePaymentMethodId'
  );
  const stripe = window.Stripe(config.stripe.publishableKey);
  let mileageOfTravel = (dropOffOdometer || 0) - pickUpOdometer;
  mileageOfTravel = mileageOfTravel > 0 ? mileageOfTravel : 0;

  const lineItems = [
    {
      code: LINE_ITEM_FUEL_CHARGING_PROVIDER_COMMISSIONS,
      unitPrice: new Money(22, config.currency),
      quantity: mileageOfTravel,
      includeFor: ['customer', 'provider'],
    },
    {
      code: LINE_ITEM_FUEL_CHARGING_DRIVELAH_COMMISSIONS,
      unitPrice: new Money(8, config.currency),
      quantity: mileageOfTravel,
      includeFor: ['customer'],
    },
  ];

  const bodyParams = {
    processAlias: bookingProcessAliasFuelCharging,
    transition: TRANSITION_FUEL_CHARGING_REQUEST,
    params: {
      listingId,
      lineItems,
    },
    isSecurityDeposit: true
  };

  const queryParams = {
    include: ['booking'],
    expand: true,
  };
  const transactionResponse = await sdk.jh.transactions.initiate(bodyParams, queryParams);

  let [fuelChargingTransaction] = denormalisedResponseEntities(transactionResponse);
  const fuelChargingId = fuelChargingTransaction.id.uuid;
  const hasPaymentIntents =
    fuelChargingTransaction.attributes.protectedData &&
    fuelChargingTransaction.attributes.protectedData.stripePaymentIntents;

  if (!hasPaymentIntents) {
    throw new Error(
      `Missing StripePaymentIntents key in fuelChargingTransaction's protectedData. Check that your fuelChargingTransaction process is configured to use payment intents.`
    );
  }
  const { stripePaymentIntentClientSecret } = hasPaymentIntents
    ? fuelChargingTransaction.attributes.protectedData.stripePaymentIntents.default
    : null;

  const args = paymentMethod
    ? [stripePaymentIntentClientSecret, { payment_method: paymentMethod }]
    : [stripePaymentIntentClientSecret, card];

  const responseCard = await stripe.handleCardPayment(...args);
  if (responseCard.error) {
    return Promise.reject(responseCard);
  } else {
    fuelChargingTransaction = await handleConfirmPaymentTransition({
      sdk,
      currentTransactionId,
      fuelChargingId,
      currentUserId,
    });
    return fuelChargingTransaction;
  }
};


export const fetchingDistanceTravelled = async (id, params, dispatch) => {
  const {odometerReadings} = params;
  const pickupReading = odometerReadings.pickUp;
  const dropOffReading = odometerReadings.dropOff;

  try {
    // Calling distance API to fetch distance travelled
    const res = await Axios.get(
      `${process.env.REACT_APP_API_SERVER_URL}/api/distanceTravelled/${id.uuid}?pickupReading=${pickupReading}&dropOffReading=${dropOffReading}`
    );

    const distance = res?.data?.distanceTravelled;

    if (distance) {
      // Updating parent transaction with fetched distance
      dispatch(updateParentTransaction(id.uuid, { distanceTravelled: distance }));

      // Returning fetched distance
      return distance;
      
    } else {
      // Updating distance charging failed in parent transaction
      dispatch(updateParentTransaction(id.uuid, { isDistanceCharged: false }));

      return Promise.reject('Distance fetched is not valid');
    }

  } catch (error) {
    console.log('Error occured while fetching distance >', error);
    
    // Updating distance charging failed in parent transaction
    dispatch(updateParentTransaction(id.uuid, { isDistanceCharged: false }));

    return Promise.reject(error);
  }
};


export const createDistanceTransaction = async ({
  id,
  currentTransaction,
  distanceTravelled,
  transactionTimezone,
  tripEndTime,
  isDropOff,
  sdk,
  dispatch
}) => {
  const currentTransactionId = get(currentTransaction, 'id.uuid');
  const listingId = get(currentTransaction, 'listing.id');

  // Preparing bodyParams and queryParams for initiating distance charging transaction
  const bodyParams = {
    processAlias: bookingProcessAliasDistanceCharging,
    transition: TRANSITION_DISTANCE_CHARGING_REQUEST_PAYMENT,
    isDistanceCharge: true,
    distanceTravelled: distanceTravelled,
    transactionTimezone: transactionTimezone,
    bookingEnd: tripEndTime,
    isDropOff: isDropOff,
    params: {
      listingId: listingId,
      protectedData: {
        parentTxId: currentTransactionId,
        distanceTravelled: distanceTravelled,
      }
    },
  };

  const queryParams = {
    include: ['booking'],
    expand: true,
  };

  try {
    // Initiating distance charging transaction
    const transactionResponse = await sdk.jh.transactions.initiate(bodyParams, queryParams);

    if (transactionResponse.status === 200) {
      let [distanceChargingTransaction] = denormalisedResponseEntities(transactionResponse);

      const distanceChargingId = distanceChargingTransaction.id.uuid;

      // Updating parent transaction with distance transaction id when initiation is successful.
      dispatch(updateParentTransaction(id.uuid, { distanceChargingId: distanceChargingId }));

      return distanceChargingTransaction;
    } else if (transactionResponse.status === 201) {
      // Updating distance charging failed in parent transaction
      dispatch(updateParentTransaction(id.uuid, { isDistanceCharged: false }));
      return Promise.reject('Distance transaction creation failed. Invoice have been generated');
    }
  } catch (error) {
    console.log('Error in creating distance transaction >', error);

    // Updating distance charging failed in parent transaction
    dispatch(updateParentTransaction(id.uuid, { isDistanceCharged: false }));
    return Promise.reject('Distance transaction creation failed > ', error)
  }
};

export const chargingForDistanceTransaction = async ({
  id,
  card = null,
  currentTransaction,
  distanceTravelled,
  distanceTransaction,
  transactionTimezone,
  tripEndTime,
  currentUser,
  sdk,
  dispatch
}) => {
  const currentTransactionId = get(currentTransaction, 'id.uuid');
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserId = ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
  const paymentMethod = get(
    ensuredCurrentUser,
    'stripeCustomer.defaultPaymentMethod.attributes.stripePaymentMethodId'
  );
  const stripe = window.Stripe(config.stripe.publishableKey);

  const distanceChargingId = distanceTransaction.id.uuid;

  const hasPaymentIntents =
  distanceTransaction.attributes.protectedData &&
  distanceTransaction.attributes.protectedData.stripePaymentIntents;


  if (!hasPaymentIntents) {
    throw new Error(
      `Missing StripePaymentIntents key in fuelChargingTransaction's protectedData. Check that your fuelChargingTransaction process is configured to use payment intents.`
    );
  }
  const { stripePaymentIntentClientSecret } = hasPaymentIntents
    ? distanceTransaction.attributes.protectedData.stripePaymentIntents.default
    : null;

  const args = paymentMethod
    ? [stripePaymentIntentClientSecret, { payment_method: paymentMethod }]
    : [stripePaymentIntentClientSecret, card];

    // Processing payment for distance

    let responseCard = null;
    try {
      responseCard = await stripe.handleCardPayment(...args);
    } catch (error) {
      console.log('Error in processing payment >', error);

      // Setting up response card error as true in case of no card is there and / or  stripe.handleCardPayment fails.
      responseCard = {error: true};
    }

  currentTransaction = await dispatch(showTransaction(currentTransactionId));

  const {
    lateReturn: existingLateReturnJson = {},
    distanceCharge: existingDistanceChargeJson = {},
    childDistanceTransaction: existingChildDistanceTransaction = {},
  } = currentTransaction?.attributes?.metadata;

  if (responseCard.error) {
    // Preparing data to sent in body while creating stripe invoice through API
    const body = {
      userId: currentUserId,
      lineItems: distanceTransaction?.attributes?.lineItems,
      parentTransactionId: currentTransactionId,
      distanceTransactionId: distanceChargingId,
      distanceTravelled: distanceTravelled,
      transactionTimezone : transactionTimezone,
      tripEndTime : tripEndTime,
    };

    // Creating stripe invoice through API
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/stripe-invoice/create-stripe-invoice`,
        body
      );
      console.log('Response of Creating invoice >> ', res);
      
    } catch (error) {
      console.error('Error creating stripe invoice:', error);
    }

    // Updating the distance transaction and transitioning it to distance charging decline
    await handleTransitionOfDistanceTransaction({
      sdk,
      currentTransactionId,
      distanceChargingId,
      currentUserId,
      transition: TRANSITION_DISTANCE_CHARGING_DECLINE,
      isDistanceCharged: false,
      distanceTravelled: distanceTravelled,
      dispatch
    });
    return Promise.reject(responseCard);

  } else {
    // If charging is successful updating the distance transaction and transitioning it to distance charging confirm payment
    const distanceTransactionAfterPayment = await handleTransitionOfDistanceTransaction({
      sdk,
      currentTransactionId,
      distanceChargingId,
      currentUserId,
      transition: TRANSITION_DISTANCE_CHARGING_CONFIRM_PAYMENT,
      isDistanceCharged: true,
      distanceTravelled: distanceTravelled,
      dispatch
    });

    try {
      const dataToUpdate = {
        "paid": true,
        "paidAtDate": new Date().toISOString(),
      }
      await dispatch(updateParentTransaction(currentTransactionId, {
        childDistanceTransaction: {...existingChildDistanceTransaction, ...dataToUpdate, "successful": true},
        distanceCharge: {...existingDistanceChargeJson, ...dataToUpdate},
        lateReturn: {...existingLateReturnJson, ...dataToUpdate},
      }));
    } catch (error) {
      console.log('Error in updating parent transaction distance jsons >', error);
    }

    // Returning distance charging transaction when charge is successful
    return distanceTransactionAfterPayment;
  }
};

export const handleSendRequest = async ({
  card = null,
  currentTransaction,
  dropOffOdometer,
  currentUser,
  sdk,
  dispatch
}) => {
const pickUpOdometer = get(currentTransaction, 'attributes.protectedData.pickUpOdometer', 0);
const currentTransactionId = get(currentTransaction, 'id.uuid');
const listingId = get(currentTransaction, 'listing.id.uuid');
const ensuredCurrentUser = ensureCurrentUser(currentUser);
const currentUserId = ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
const paymentMethod = get(
ensuredCurrentUser,
'stripeCustomer.defaultPaymentMethod.attributes.stripePaymentMethodId'
);
const stripe = window.Stripe(config.stripe.publishableKey);
let mileageOfTravel = (dropOffOdometer || 0) - pickUpOdometer;
mileageOfTravel = mileageOfTravel > 0 ? mileageOfTravel : 0;

const bodyParams = {
processAlias: bookingProcessAliasDistanceCharging,
transition: TRANSITION_DISTANCE_CHARGING_REQUEST_PAYMENT,
isDistanceCharge: true,
params: {
listingId,
},
};

const queryParams = {
include: ['booking'],
expand: true,
};
const transactionResponse = await sdk.jh.transactions.initiate(bodyParams, queryParams);

let [distanceChargingTransaction] = denormalisedResponseEntities(transactionResponse);
const distanceChargingId = distanceChargingTransaction.id.uuid;
const hasPaymentIntents =
distanceChargingTransaction.attributes.protectedData &&
distanceChargingTransaction.attributes.protectedData.stripePaymentIntents;

if (!hasPaymentIntents) {
throw new Error(
`Missing StripePaymentIntents key in fuelChargingTransaction's protectedData. Check that your fuelChargingTransaction process is configured to use payment intents.`
);
}
const { stripePaymentIntentClientSecret } = hasPaymentIntents
? distanceChargingTransaction.attributes.protectedData.stripePaymentIntents.default
: null;

const args = paymentMethod
? [stripePaymentIntentClientSecret, { payment_method: paymentMethod }]
: [stripePaymentIntentClientSecret, card];

const responseCard = await stripe.handleCardPayment(...args);
if (responseCard.error) {
return Promise.reject(responseCard);
} else {
distanceChargingTransaction = await handleTransitionOfDistanceTransaction({
sdk,
currentTransactionId,
distanceChargingId,
currentUserId,
dispatch
});
return distanceChargingTransaction;
}
};
const handleConfirmPaymentTransition = async ({
  sdk,
  currentTransactionId,
  fuelChargingId,
  currentUserId,
}) => {
  const confirmParams = {
    id: fuelChargingId,
    transition: TRANSITION_FUEL_CHARGING_CONFIRM_PAYMENT,
    params: {},
  };

  if (!currentUserId || !currentTransactionId || !fuelChargingId) {
    throw new Error(`Missing current user or current transaction`);
  }

  const response = await sdk.transactions.transition(confirmParams, {
    include: ['booking', 'provider'],
    expand: true,
  });
  const [transaction] = denormalisedResponseEntities(response);
  const updateFuelChargingURL = `transactions/${currentTransactionId}/${fuelChargingId}/update-fuel-charging`;
  await drivelahApiPut(updateFuelChargingURL);
  return transaction;
};

export const handleTransitionOfDistanceTransaction = async ({
  sdk,
  currentTransactionId,
  distanceChargingId,
  currentUserId,
  transition,
  isDistanceCharged,
  dispatch
}) => {
  const confirmParams = {
    id: distanceChargingId,
    transition: transition,
    params: {},
  };

  if (!currentUserId || !currentTransactionId || !distanceChargingId) {
    throw new Error(`Missing current user or current transaction`);
  }

  // Transitioning the distance transaction to success or failed distance transaction
  const response = await sdk.transactions.transition(confirmParams, {
    include: ['booking', 'provider'],
    expand: true,
  });
  const [transaction] = denormalisedResponseEntities(response);

  // Updating parent transaction with distance charging failed or success
  dispatch(updateParentTransaction(currentTransactionId, {
    isDistanceCharged: isDistanceCharged,
    distanceChargeFrom: "web"
  }));

  // Returning the updated transaction
  return transaction;
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};

export const uploadInteriorPhotoToMetadata = (id, photoObjects = null) => (dispatch, getState, sdk) => {
  dispatch(uploadInteriorPhotoRequest())
  return fetch(apiUrl + "/api/update-transaction-metadata", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      transactionId: id.uuid,
      photoObjects,
    })
  })
  .then(response => {
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    return response.json();
  })
  .then(response => {
    dispatch(uploadInteriorPhotoSuccess())
  })
  .catch(error => {
    dispatch(uploadInteriorPhotoError())
  });
}

export const cancelAllUpdateBookings = bookingTxId => (dispatch, getState, sdk) => {
  return editTripApi.cancelAllUpdateBookingsRequest(bookingTxId, sdk);
};


export const $userLocation = state => {
  return state.TransactionPage.userLocation;
};

export const $distanceFromPickUp = state => {
  return state.TransactionPage.distanceFromPickUp;
};

