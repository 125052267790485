import React from 'react';
import css from './StartTripPanel.css';
import locationImage from '../../assets/newPickupAndDropoff/mapsk.png';

import { PrimaryButton } from '..';

export default function NeedUserPermission({ getDistanceFromPickUp, listingForMap, setLocationPermission, isPickUp}) {

  const handleClick = async () => {
    try {
      // Check if permissions.query is supported
      if (navigator.permissions && navigator.permissions.query) {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        if (permissionStatus.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              getDistanceFromPickUp({ latitude, longitude });
            },
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                alert(
                  'Location access is denied. Please enable it in your browser settings to proceed.'
                );
              } else {
                console.error('Geolocation error:', error);
                alert('An error occurred while fetching your location. Please try again.');
              }
            }
          );
          return;
        }
        if (permissionStatus.state === 'denied') {
          alert(
            'Location permission is blocked. Please enable it in your browser settings to proceed.'
          );
          return;
        }
      }
    } catch (error) {
      console.error('Error checking geolocation permission:', error);
    }
  };

  const actionText = isPickUp
    ? 'start'
    : 'end';

  React.useEffect(() => {
    const handlePermission = async () => {

      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
        setLocationPermission(permissionStatus.state);
        permissionStatus.onchange = () => {
          setLocationPermission(permissionStatus.state);

          handleClick();
        };
      } catch (error) {
        console.error('Error querying geolocation permission:', error);
      }
    };

    handlePermission();

    return () => {
      const permissionStatus = navigator.permissions.query({ name: 'geolocation' });
      if (permissionStatus) {
        permissionStatus.onchange = '';
      }
    };
  }, []);

  return (
    <div className={css.locationMap} style={{ backgroundImage: `url(${locationImage})` }}>
      <div className={css.messageWrapper}>
        <span>
        We need location access to ensure you are at the correct location to {actionText} the trip. Please allow location access in your browser settings.
        </span>
      </div>
      <PrimaryButton onClick={() => handleClick()} className={css.buttonAccepted}>
        Give permission
      </PrimaryButton>
    </div>
  );
}
