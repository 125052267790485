import React, {useCallback, useEffect, useState} from 'react';
import {bool, func, shape, string} from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Form as FinalForm} from 'react-final-form';
import {injectIntl, intlShape} from '../../../util/reactIntl';
import classNames from 'classnames';
import {propTypes} from '../../../util/types';
import setFieldTouched from '../../../util/setFiledTouched';
import {Button, SavedCardDetails} from '../../../components';

import css from './index.css';
import SubscriptionPaymentForm from '../SubscriptionPaymentForm';
import {deletePaymentMethod, savePaymentMethod} from '../../../ducks/paymentMethods.duck';
import {handleCardSetup} from '../../../ducks/stripe.duck';
import {isScrollingDisabled, manageDisableScrolling} from '../../../ducks/UI.duck';
import {
  ensureCurrentUser,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
} from '../../../util/data';
import {types as sdkTypes} from '../../../util/sdkLoader';
import {
  createStripeSetupIntent,
  loadData,
  stripeCustomer,
} from '../../../ducks/subscriptionPanel.duck';
import {fetchCurrentUser} from '../../../ducks/user.duck';
import config from '../../../config';
import SubscriptionsCardSection from './Components/SubscriptionsCardSection';
import SubscriptionAddOns from './Components/adsOnBadge';
import activeListingSvg from "../../../assets/landingPageNew/activeListingSvg.svg";

const {LatLng} = sdkTypes;

const HELP_LINK = process.env.REACT_APP_SUBSCRIPTION_PLAN_HELP;

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const SUBSCRIPTION_PLAN = {
  // "just-mates": {
  //   label: 'Just mates',
  //   type: 'Free',
  //   gpsText: 'Bring your own GPS',
  //   mileageText: 'Mileage reporting to be done by you',
  //   lockText: 'In-person key handover to guests',
  //   value: 'just-mates',
  //   subscriptionData: [
  //     {
  //       id: "just-mates-secondary",
  //       content: "Secondary GPS - $5/month",
  //       badge: null
  //     }
  //   ]
  // },
  "good-mates": {
    label: 'Good mates',
    type: '$10',
    gpsText: 'Primary GPS included',
    mileageText: 'Automated mileage calculations',
    lockText: 'In-person key handover to guests',
    lockTextForLockbox: 'Remote handover to guests',
    value: 'good-mates',
    subscriptionData: [
      {
        id: "good-mates-secondary",
        content: "Secondary GPS - $5/month",
        badge: null
      },
      {
        id: "good-mates-lockbox",
        content: "Lockbox - $10/month",
        badge: null
      }
    ],
    isMonth: true
  },
  "best-mates": {
    label: 'Best mates',
    type: '$30',
    gpsText: 'Keyless access technology',
    mileageText: 'Automated mileage calculations',
    lockText: 'Remote handover to guests',
    value: 'best-mates',
    subscriptionData: [
      {
        id: "best-mates-secondary",
        content: "Secondary GPS - $5/month",
        badge: null
      },
      // {
      //   id: "best-mates-insurance",
      //   content: "Between trip insurance",
      //   badge: "Coming soon",
      //   isDisable: true,
      // }
    ],
    isMonth: true
  },
};

const DEFAULT_PLAN_VALUE = SUBSCRIPTION_PLAN["good-mates"].value;

export const SubscriptionsForm = props => {

  const {
    currentUser,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    deletePaymentMethodInProgress,
    onCreateSetupIntent,
    onHandleCardSetup,
    onSavePaymentMethod,
    onDeletePaymentMethod,
    fetchStripeCustomer,
    scrollingDisabled,
    onManageDisableScrolling,
    intl,
    stripeCustomerFetched,
    fetchCurrentUser,
    initValues,
    setAddsOn,
    addsOn,
    subscriptionPlan,
    subscriptionPlanAddOn,
    live,
    isEligibleForFreeze
  } = props;

  console.log("Is elibible for freeze", isEligibleForFreeze);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardState, setCardState] = useState(null);
  const [mountingCard, setMountingCard] = useState(null);
  const [stripeValue, setStripeValue] = useState(null);
  const [isStickySubmitVisible, setIsStickySubmitVisible] = useState(false);
  const [isError, setIsError] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planValue, setPlanValue] = useState(initValues.plan || DEFAULT_PLAN_VALUE)
  const [showDevice, setShowDevice] = useState(false)
  const [showChangeError, setShowChangeError] = useState(false);

  useEffect(() => {
    if (Object.values(SUBSCRIPTION_PLAN).length > 0) {
      setSelectedPlan(SUBSCRIPTION_PLAN[initValues.plan] || SUBSCRIPTION_PLAN[DEFAULT_PLAN_VALUE]);
    }
    if(subscriptionPlan) {
      setSelectedPlan(SUBSCRIPTION_PLAN[subscriptionPlan])
    }
  }, []);

  useEffect(()=> {
    props.updateShowDeviceTab()
  }, [])


  const handleSelectPlan = plan => {
    setSelectedPlan(plan);
    setPlanValue(plan.value)
  };

  const getClientSecret = setupIntent => {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  };

  const getPaymentParams = (currentUser, formValues) => {
    const {name, addressLine1, addressLine2, postal, state, city, country} = formValues;
    const addressMaybe =
      addressLine1 && postal
        ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const paymentParams = {
      payment_method_data: {
        billing_details: billingDetails,
      },
    };

    return paymentParams;
  };

  const onStripeCall = async params => {
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const {stripe, card, formValues} = params;
    const container = document.getElementById('subscriptionForm-card');

    if (container) {
      await onCreateSetupIntent()
        .then(setupIntent => {
          const stripeParams = {
            stripe,
            card,
            setupIntentClientSecret: getClientSecret(setupIntent),
            paymentParams: getPaymentParams(currentUser, formValues),
          };
          mountingCard.mount(container);
          return onHandleCardSetup(stripeParams);
        })
        .then(result => {
          const newPaymentMethod = result.setupIntent.payment_method;
          // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.
          return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
        })
        .then(() => {
          // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
          fetchStripeCustomer();
          fetchCurrentUser();
          setCardState('default');
          props.onSubmit(formValues);
        })
        .catch(error => {
          if (error.message) {
            setIsError(error.message);
          } else {
            setIsError(error.toString());
          }

          console.error(error);
        });
    } else {
      props.onSubmit(formValues);
    }
  };

  const handleRemovePaymentMethod = () => {
    onDeletePaymentMethod().then(() => {
      fetchStripeCustomer();
    });
  };

  useEffect(() => {
    props.initData();
  }, []);

  const handleScroll = useCallback(() => {
    let scrollTop = window.pageYOffset;
    // if (this.stickySubmitButtonRef) {
    //   const isVisible =
    //     parseInt(scrollTop + (window.innerHeight - 70)) >
    //     parseInt(this.stickySubmitButtonRef.current.offsetTop)
    //       ? true
    //       : false;
    //   this.setState({
    //     isStickySubmitVisible: !isVisible,
    //   });
    // }
  }, []);

  const handleSubmit = async (values, onSuccessCallback) => {
    setIsSubmitting(true);
    setIsError(false);

    const params = {
      stripe: stripeValue,
      card: mountingCard,
      formId: 'subscriptionForm',
      formValues: values,
    };

    await onStripeCall(params);
    setIsSubmitting(false);

    if (!isError) {
      onSuccessCallback();
    }
  };

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for PaymentMethodsForm');
    }

    if (config.stripe.publishableKey) {
      const stripe = window.Stripe(config.stripe.publishableKey);

      const elements = stripe.elements(stripeElementsOptions);
      const cardToMount = elements.create('card', {style: cardStyles});
      setMountingCard(cardToMount);
      setStripeValue(stripe);
      window.addEventListener('resize', () => {
        if (window.innerWidth < 1024) {
          cardToMount.update({style: {base: {fontSize: '18px', lineHeight: '24px'}}});
        } else {
          cardToMount.update({style: {base: {fontSize: '20px', lineHeight: '32px'}}});
        }
      });
    }
    if (isStickySubmitVisible) {
      handleScroll();
    }
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;

  const hasDefaultPaymentMethod =
    currentUser &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;

  // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
  const userName = currentUserLoaded
    ? `${ensuredCurrentUser.attributes.profile.firstName} ${ensuredCurrentUser.attributes.profile.lastName}`
    : null;
  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  const {blockNo, building, floorUnit, city, country, postalCode} = protectedData;
  const {selectedPlace} = protectedData.location || {};
  const {address, origin} = selectedPlace || {};
  const initalValuesForStripePayment = {
    name: userName,
    blockNo,
    addressLine1: address,
    addressLine2: blockNo,
    location: {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    building,
    floorUnit,
    city,
    country,
    postalCode,
    postal: postalCode,
  };

  const isDataFetched = initalValuesForStripePayment.name;
  const card = hasDefaultPaymentMethod
    ? ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).attributes.card
    : null;
  const showForm = isDataFetched && (cardState === 'replaceCard' || !hasDefaultPaymentMethod);
  const showCardDetails = !!hasDefaultPaymentMethod;

  return (
    <FinalForm
      {...props}
      initialValues={{
        plan: initValues.plan || DEFAULT_PLAN_VALUE,
      }}
      mutators={{setFieldTouched}}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          // handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
          submitButtonId,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress || isSubmitting;
        const submitDisabled = invalid || disabled || submitInProgress || isError;
        const isPaymentVisible = stripeCustomerFetched;
        setShowDevice(isPaymentVisible)

        const subscriptionPlanComponents = (
          <div className={css.planWrapper}>
            <span>Select your plan</span>
            <div className={css.subscriptionWrapper}>
              {Object.values(SUBSCRIPTION_PLAN).map((plan, index) => (
                <SubscriptionsCardSection
                  subscriptionPlan={plan}
                  key={index}
                  setShowChangeError={setShowChangeError}
                  isEligibleForFreeze={isEligibleForFreeze}
                  isSelected={selectedPlan === plan}
                  onSelect={() => handleSelectPlan(plan)}
                  subscriptionPlanAddOn={subscriptionPlanAddOn}
                  addsOn={addsOn}
                />
              ))}
            </div>
          </div>
        );

        return (
          <>
            <div>{subscriptionPlanComponents}</div>
            <div className={css.addsOnsection}>
              <SubscriptionAddOns
                setShowChangeError={setShowChangeError}
                isEligibleForFreeze={isEligibleForFreeze}
                subscriptionAddsOnData={SUBSCRIPTION_PLAN[planValue].subscriptionData}
                setSubscriptionAddsOn={setAddsOn}
                addOns={addsOn}
                selectedAddOn={subscriptionPlanAddOn}
                selectedPlan={planValue}
              />
            </div>
            <div>
              {isPaymentVisible ? (

                <div className={css.cardSection}>
                  <label className={css.cardDetailsText}>
                    {intl.formatMessage({
                      id: 'EditListingSubscriptionPanel.addCardDetails.title',
                    })}
                  </label>
                  {showCardDetails ? (
                    <SavedCardDetails
                      card={card}
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onChange={setCardState}
                      onDeleteCard={handleRemovePaymentMethod}
                      deletePaymentMethodInProgress={deletePaymentMethodInProgress}
                      preventRemoveCard={true}
                      cardInfo={css.cardClass}
                    />
                  ) : null}
                  {showForm ? (
                    <SubscriptionPaymentForm
                      className={css.paymentForm}
                      formId="subscriptionForm"
                      initialValues={initalValuesForStripePayment}
                      form={form}
                      handleRemovePaymentMethod={handleRemovePaymentMethod}
                      hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                      addPaymentMethodError={addPaymentMethodError}
                      deletePaymentMethodError={deletePaymentMethodError}
                      createStripeCustomerError={createStripeCustomerError}
                      handleCardSetupError={handleCardSetupError}
                      inProgress={isSubmitting}
                      isStickySubmitVisible={isStickySubmitVisible}
                      mountingCard={mountingCard}
                      card={card}
                    />
                  ) : null}
                  <p className={css.paymentFormText}>{intl.formatMessage({
                    id: 'EditListingSubscriptionPanel.cardPaymentInfoText',
                  })}</p>
                </div>
              ) : null}
            </div>

            {isError && (
              <div className={classNames(css.column, css.column12, css.error)}>{isError}</div>
            )}
            <div className={classNames(css.column, css.column12, css.infoSection)}>
              <div className={css.learnMore}>
                {intl.formatMessage({
                  id: 'EditListingSubscriptionPanel.learnMore',
                })}
                {' - '}
                <a href={HELP_LINK} target="_blank">
                  {intl.formatMessage({
                    id: 'EditListingSubscriptionPanel.learnMoreLink',
                  })}
                </a>
              </div>
              <div className={css.learnMore}>
                {intl.formatMessage({
                  id: 'EditListingSubscriptionPanel.talkToHost',
                })}
              </div>
            </div>
            <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}>
                  {showChangeError && (
                    <div className={css.stickButtonDescriptionChild}>
                      <img src={activeListingSvg} alt="Active listing warning" className={css.descIcon} />
                      <p>Active listings cannot change their Subscription directly. Please contact our support team for assistance.</p>
                    </div>
                  )}
                </div>
                <div className={css.stickButton}>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                    id={submitButtonId}
                    onClick={e => {
                      handleSubmit(planValue, () => {
                        console.log("planValue", planValue)
                        props.onSubmit(planValue); // Pass a callback to trigger EditListingSubscriptionPanel's submit
                      });
                    }}
                  >
                    {live ? 'Save' : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

SubscriptionsForm.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

SubscriptionsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

const mapStateToProps = state => {
  const {currentUser} = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const {stripeCustomerFetched} = state.subscriptionPanel;

  const {handleCardSetupError} = state.stripe;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
  initData: () => dispatch(loadData()),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
});

const SubscriptionsFormModified = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SubscriptionsForm);

export default SubscriptionsFormModified;
