import React from 'react';
import css from './LandingPageNew.css';
import BLandingCss from './LandingPageNew.css';
import PRNewswire from '../../assets/landingPageNew/prnewswire.svg'
import PRNewswire2x from '../../assets/landingPageNew/prnewswire@2x.png'
import News from '../../assets/landingPageNew/news.svg'
import Rating from '../../assets/landingPageNew/appStoreStarRatings.svg'
import AppStoreIcon from '../../assets/landingPageNew/appStore.svg'

const ComparisonTable = () => {


  const handlePRNewswireClick = () => window.open('https://www.prnewswire.com/apac/news-releases/drive-mate-secures-strategic-investment-from-comfortdelgro-301971281.html', '_blank');
  const handleNewsClick = () => window.open('https://www.news.com.au/technology/motoring/motoring-news/ubers-carshare-rival-booms-as-stranded-aussies-seek-options/news-story/cbf78c046cbcef641a1c3b38953130d6', '_blank');

  return (
    <div className={css.tableSection}>
      <div className={BLandingCss.trustBox}>
      <div onClick={handlePRNewswireClick} className={BLandingCss.trustBoxWrapper}>
          <p>Featured on</p>
          <img srcSet={`${PRNewswire} 1x, ${PRNewswire2x} 2x`} alt="PR Newswire Icon" />
          <p> & </p>
          <img className={BLandingCss.trustBoxNewsIcon} onClick={handleNewsClick} src={News} alt="news icon" />
        </div>
        <div>
          <p>Rated <img className={BLandingCss.trustimg} src={Rating} alt="Rating" /> on <img className={BLandingCss.trustimg} src={AppStoreIcon} alt="App Store Icon" /></p>
        </div>
      </div>
    </div>
  );
};

export default ComparisonTable;
