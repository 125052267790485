/**
 * This component will show the unpaid/open invoices of customers during checkout.
 */
import { array, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import styles from './UnpaidInvoices.css';
import IconSpinner from '../IconSpinner/IconSpinner';
import helpImg from '../../assets/newPickupAndDropoff/FAQ-BLUE.svg';
import ModalHelp from '../ModalHelp/ModalHelp';

export const UnpaidInvoicesComponent = ({
  openInvoices,
  onRefreshInvoices
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState(openInvoices || []);
  const [showHelpModaL, setShowHelpModaL] = useState(false);

  useEffect(() => {
    if (openInvoices) {
      setIsLoading(false);
      setInvoices(openInvoices);
    }
  }, openInvoices);

  const hanleRefeshInvoices = () => {
    setIsLoading(true);
    onRefreshInvoices();
  }

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <IconSpinner />
        <div>Please wait...</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.outerContainer}>

        <div className={styles.header}>
          <p>Clear unpaid dues</p>
          <div className={styles.helpBtn}>
            <button className={styles.needHelpBtn} onClick={() => setShowHelpModaL(true)}> <img src={helpImg} alt='#' />Need help?</button>
          </div>
        </div>

        <p className={styles.subHeader}>You have unpaid dues, so you cannot make a new booking at this time. Please review your unpaid invoices below and click 'Pay Now' to settle them.</p>

        <div className={styles.unpaidInvoicesContainer}>

          {invoices?.length && invoices.map((invoice, index) => (
            <div key={invoice.invoiceId} className={styles.invoiceListContainer}>
              <div className={styles.invoiceContent}>
                <div className={styles.invoiceDetails}>
                  
                  <div className={styles.invoiceNumber}>
                    Invoice #{invoice.invoiceNumber}
                  </div>
                  
                  <div className={styles.invoiceAmount}>
                    ${invoice.total.toFixed(2)}
                  </div>
                  
                </div>

                <a href={invoice.invoiceLink} target="_blank" rel="noopener noreferrer" className={styles.invoiceLink}>
                  <button className={styles.payButton}>Pay now</button>
                </a>
              
              </div>

              {invoice.description && invoice.description.split('\n').map((item, index) => (
                <div key={index} className={styles.invoiceDescription}>{item}</div>
              ))}

            </div>
          )
          )}

        </div>
      </div>

      <div className={styles.bottomBar}>
        <p>Made the payment? Refresh page to update status</p>
        <button type="button" onClick={hanleRefeshInvoices} className={styles.reutrnToHomeBtn}>Refresh invoices</button>
      </div>

      <ModalHelp
        onClose={() => setShowHelpModaL(false)}
        isOpen={showHelpModaL}
      />
    </>
  );
};

UnpaidInvoicesComponent.defaultProps = {
  openInvoices: [],
};

UnpaidInvoicesComponent.propTypes = {
  openInvoices: array.isRequired,
  onRefreshInvoices: func.isRequired,
};

const UnpaidInvoices = injectIntl(UnpaidInvoicesComponent);

UnpaidInvoices.displayName = 'UnpaidInvoices';

export default UnpaidInvoices;
