import React, { useEffect, useState } from 'react';
import styles from './DistanceChargeFailedPage.module.css';
import { IconSpinner, Logo, NamedLink, Page, PrimaryButton } from '../../components';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { showTransaction } from '../TripDetailsPage/TripDetailsPage.duck';
import CustomBreakdown from '../../components/CustomBrekdown/CustomBreakdown';

const DistanceChargeFailedPage = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [childDistanceTransaction, setChildDistanceTransaction] = useState({});
  const [distanceCharge, setDistanceCharge] = useState({});
  const [lateReturn, setLateReturn] = useState({});
  const [totalDue, setTotalDue] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    console.log('Entered in use effect');
    const pathSegments = window.location.pathname.split('/');
    const transactionId = pathSegments[2];

    if (isEmpty(distanceCharge)) {
      dispatch(showTransaction(transactionId))
        .then(res => {
          console.log('Response on a transaction >>', res);
          const childDistanceTransaction = res?.attributes?.metadata?.childDistanceTransaction;
          const distanceCharge = res?.attributes?.metadata?.distanceCharge;
          const lateReturn = res?.attributes?.metadata?.lateReturn;
          setChildDistanceTransaction(childDistanceTransaction);
          setDistanceCharge(distanceCharge);
          setLateReturn(lateReturn);
          setIsLoading(false);
        }).catch(error => {
          console.log('Error in fetching data > ', error);
        })
    }
  }, []);

  const handleTotalDue = total => setTotalDue(total);

  const onPayNowButtonClick = () => {
    const invoiceLink = distanceCharge?.invoicePaymentLink;
    if (invoiceLink) {
      window.open(invoiceLink, '_blank', 'noopener,noreferrer');
      window.history.back();
    };
  };

  const pageProps = { title: 'Distance charge failed', scrollingDisabled: false, className: styles.root };


  return (
    <Page {...pageProps}>
      {/* Top bar Starts */}
      <div className={styles.topbar}>
        <NamedLink className={styles.home} name="LandingPage">
          <Logo
            className={styles.logoMobile}
            title='Go to landing page'
            format="mobile"
          />
          <Logo
            className={styles.logoDesktop}
            alt='Go to landing page'
            format="desktop"
          />
        </NamedLink>
      </div>
      {/* Top bar Ends */}

      {/* Loader */}
      {isLoading && (
        <div className={styles.loading}>
          <IconSpinner />
          <div>Fetching Data...</div>
        </div>
      )}

      {/* Content */}
      {!isLoading && (
        <div className={styles.outerBox}>
          <div className={styles.title}>Trip end payment failed</div>
          <div className={styles.outerContainer}>
            {/* <div className={styles.containerLeft}>
              <div className={styles.errorForFailedTransaction}>
                <p>{`Payment of ${totalDue} failed`}</p>
                <p>
                  <p>
                    Your card was declined
                  </p>
                  <p>
                    Our payment provider declined the card due to low balance. Please use a different payment method.
                  </p>
                </p>
              </div>
            </div> */}
            <div className={styles.containerLeft}>
              <h3>Charge Details</h3>
              <CustomBreakdown
                childDistanceTransactionJSON={childDistanceTransaction}
                distanceChargeJSON={distanceCharge}
                lateReturnJSON={lateReturn}
                layout={'distanceChargePage'}
                onTotalChange={handleTotalDue}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.submitSection}>
        <div className={styles.submitSectionInner}>
          <div className={styles.submitSectionPrice}>
            <span className={styles.totalAmount}>
              {`$${totalDue}`}
            </span>
            Due now
          </div>
          <div className={styles.submitSectionPrice}>
            <div className={styles.submitContainer}>
              <PrimaryButton
                id='payingInvoice'
                className={styles.submitButton}
                onClick={onPayNowButtonClick}
              >
                Pay now
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default DistanceChargeFailedPage;