import config from '../config';
import { sendGAEvent } from './googleAnalytics';

const apiUrl = config.apiUrl;

export const fetchBrandlist = () => {
  return fetch(apiUrl + "/api/car-prices/available-car-brands", {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
}

export const fetchModellist = (brandName) => {
  return fetch(apiUrl + "/api/car-prices/available-car-models?carBrand=" + brandName, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
}

export const fetchPricelist = (brandName, modelName, yearOfManufacture, listingid) => {
  sendGAEvent({
    eventCategory: 'Others',
    eventAction: 'Use The Rental Calculator',
  });

  let params =  {
    carBrand: brandName,
    carModel: modelName
  }
  if (yearOfManufacture)  params.yearOfManufacture = yearOfManufacture;
  if (yearOfManufacture)  params.listingid = listingid;

  const queryParams = new URLSearchParams(params);
  console.log("query patams", queryParams)

  return fetch(apiUrl + "/api/car-prices?" + queryParams.toString(), {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
};
