import React, { Component } from 'react';
import PropTypes, { array, arrayOf, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import helpImg from '../../assets/newPickupAndDropoff/FAQ.svg';
import isEmpty from 'lodash/isEmpty';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  getUserTxRole,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  txAfterCancelUpdateTrip,
  txHasBeenDelivered,
  txHasFirstReviewedByCustomer,
  txHasFirstReviewedByProvider,
  txInVerificationProcess,
  txIsAccepted,
  txIsAcceptedUpdateBookingBeforeDropOff,
  txIsAcceptedUpdateBookingBeforePickUp,
  txIsAutoAcceptedUpdateBookingBeforeDropOff,
  txIsAutoAcceptedUpdateBookingBeforePickUp,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsCanceled,
  txIsCancelledUpdateBookingBeforeDropOff,
  txIsCancelledUpdateBookingBeforePickUp,
  txIsCompleted,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPickedUp,
  txIsRequested,
  txIsShu,
  txIsUnverifiedInstantRequested,
  txIsUnverifiedRequested,
  txIsUnverifiedSubmitted,
  txIsUnverifiedSubmittedInstant,
  txIsWithdrawn,
  txIsWithdrawnByAdmin,
  txIWaitingRequestDropoff,
  txIWaitingRequestPickup,
  txIsReturnedBackBeforePickUp,
} from '../../util/transaction';
import {
  txIsBeingPickedUpLTF,
  txIsDeclinedLTF,
  txIsPickedUpLTF,
  txIsRequestedLTF,
  txIWaitingRequestPickupLTF,
  txLastTransition,
} from '../../util/transactionLongTermFirst';
import {
  txHasFirstReviewedByCustomerLTL,
  txHasFirstReviewedByProviderLTL,
  txIsBeingDropOffLTL,
  txIsCompletedLTL,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import { propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import {
  ModalCancel,
  ModalCancelPolicy,
  ModalHelp,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import {
  CANCEL_BOOKING_BUTTON_ID,
  EVENT_BOOK_REQUEST_WITHDRAWN_GUEST,
  EVENT_TRIP_DISPUTED_GUEST,
  EVENT_TRIP_DISPUTED_HOST,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_DROPOFF_REQUEST_RECEIVED,
  EVENT_TRIP_DROPOFF_REQUEST_SENT,
  EVENT_TRIP_GUEST_CANCEL_GUEST,
  EVENT_TRIP_GUEST_REVIEW_GUEST,
  EVENT_TRIP_GUEST_REVIEW_HOST,
  EVENT_TRIP_HOST_CANCEL_HOST,
  EVENT_TRIP_HOST_REVIEW_GUEST,
  EVENT_TRIP_HOST_REVIEW_HOST,
  EVENT_TRIP_MESSAGE_SENT_GUEST,
  EVENT_TRIP_MESSAGE_SENT_HOST,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_PICKUP_REQUEST_RECEIVED,
  EVENT_TRIP_PICKUP_REQUEST_SENT,
  SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST,
  SERVER_EVENT_TRIP_GUEST_CANCEL_HOST,
  SERVER_EVENT_TRIP_HOST_CANCEL_GUEST,
  TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
  TRIP_PUBLISH_REVIEW_BUTTON_ID,
  TRIP_SEND_MESSAGE_BUTTON_ID,
  TRIP_SUBMIT_DISPUTE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';

import {
  initiateEventFromTransaction,
  pushGTMTripEvent,
} from '../../util/gtm/gtmHelpers';
import StartTripAddressLinkMaybe from './StartTripAddressLinkMaybe';
import FeedSection from './FeedSection';
import StartTripPanelHeading, {
  HEADING_ACCEPTED,
  HEADING_CANCELED,
  HEADING_DECLINED,
  HEADING_DELIVERED,
  HEADING_ENQUIRED,
  HEADING_ONGOING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_REQUESTED,
  HEADING_WITHDRAWN,
  HEADING_WITHDRAWN_BY_ADMIN,
} from './StartTripPanelHeading';
import {
  HEADING_ACCEPTED_UPDATE_BOOKING,
  HEADING_DECLINED_UPDATE_BOOKING,
  HEADING_REQUEST_UPDATE_BOOKING,
} from '../TransactionPanel/PanelHeading';
import css from './StartTripPanel.css';
import { dropoffTransitions, LONG_TERM_STATUS_FINISH } from './DropOffButtonsMaybe';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import StartTripListingMap from './StartTripListingMap';
import CustomerMap from './CustomerMap';
import BookingStatus from './BookingStatus';
import UpdateBookingButtonsMaybe from './UpdateBookingButtonsMaybe';
import ButtonsSection from './ButtonsSection';
import { pickupTransitions } from './PickupButtonsMaybe';
import { getNextCancelTransition, getNextInteractableTransition } from './transitionHelper';
import ModalReasonDispute from './ModalReasonDispute';
import TripInfo from './TripInfo';
import PickupAndDropPage from '../../containers/PickAndDropPage/PickAndDropPage';
import TripInstructionsSlider from './TripInstructionsSlider';


const SHU_STATUS_DISPLAY_STATUS = {
  INIT: 0,
  SUCCESS: 1,
  ERROR: 2,
  LOADING: 3,
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;
  let otherUser = null;
  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUser = currentProvider;
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUser = currentCustomer;
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
    otherUser,
  };
};

export class StartTripPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isCancelPolicyOpen: false,
      reviewSubmitted: false,
      isStatic: true,
      showCancelModal: false,

      showCancelHelpModal: false,
      showDisputeModal: false,
      showImgModal: false,
      showImgModalUrl: null,
      reasonDispute: '',
      currentPhotos: [],
      tripPhotos: [],
      exteriorPhotos: [],
      selfieWithId: [],
      interiorPhotos: [],
      odometerPhotos: [],
      fuelReceiptPhotos: [],
      fuelGaugePhotos: [],
      pickupProviderPhoto: [],
      reasonDisputePhotoUrls: [],
      isOpenRentalAgreement: false,
      userCloseModal: false,
      numberOdometer: null,
      isReadMore: null,
      showPickUpSection: false,
      showDropOffSection: false,
      activePhotoSection: [],
      editWithAdditionalTime: false,
      isEditBookingActive: false,
      isDonateTransactionActive: false,
      shuStatusLocation: {
        status: SHU_STATUS_DISPLAY_STATUS.INIT,
        location: {},
        address: '',
      },
      showCancelTripInstructionsModal: false,
      showStartTripStep: false,
      showStartTripButton: false
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.shouldShowPickUpSection = this.shouldShowPickUpSection.bind(this);
    this.shouldShowDropOffSection = this.shouldShowDropOffSection.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
    this.initShuStatusLocation(this.props.transaction);
  }

  showStartTripStep = (value) => {
    this.setState({ showStartTripStep: value });
  }


  componentWillReceiveProps(nextProps) {
    if (!this.state.userCloseModal) {
      if (!this.state.isReviewModalOpen) {
        const { transaction, transactionRole } = nextProps;
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        if (transaction) {
          const isCompleted = txIsCompleted(transaction) || txIsCompletedLTL(transaction);
          const isReviewedByCustomer =
            txHasFirstReviewedByCustomer(transaction) ||
            txHasFirstReviewedByCustomerLTL(transaction);
          const isReviewedByProvider =
            txHasFirstReviewedByProvider(transaction) ||
            txHasFirstReviewedByProviderLTL(transaction);

          if (isCompleted) {
            if (isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          } else {
            if (isReviewedByCustomer && isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isReviewedByProvider && isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          }
        }
      }
    }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    let currentTransaction = ensureTransaction(transaction);
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    if (isFinishLongTerm) {
      currentTransaction = transaction && transaction.currentChildTransaction;
    }
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const events =
      transactionRole === 'provider'
        ? [EVENT_TRIP_HOST_REVIEW_GUEST, EVENT_TRIP_HOST_REVIEW_HOST]
        : [EVENT_TRIP_GUEST_REVIEW_HOST, EVENT_TRIP_GUEST_REVIEW_GUEST];

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => {
        pushGTMTripEvent({
          props: this.props,
          event: events[0],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
          userDiffActionTaker: true,
        });
        pushGTMTripEvent({
          props: this.props,
          event: events[1],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
        });
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    const event =
      transactionRole === 'provider' ? EVENT_TRIP_MESSAGE_SENT_HOST : EVENT_TRIP_MESSAGE_SENT_GUEST;

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        pushGTMTripEvent({
          props: this.props,
          event: event,
          transaction,
          buttonId: TRIP_SEND_MESSAGE_BUTTON_ID,
          existTransaction: true,
        });
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleChooseDisputePhoto(fileUrl) {
    const isExisted = this.state.reasonDisputePhotoUrls.find(url => url === fileUrl);
    if (isExisted) {
      this.setState({
        reasonDisputePhotoUrls: this.state.reasonDisputePhotoUrls.filter(url => url !== fileUrl),
      });
    } else {
      this.setState({
        reasonDisputePhotoUrls: [...this.state.reasonDisputePhotoUrls, fileUrl],
      });
    }
  }

  onPushDisputeEventGTM = () => {
    const { transaction, transactionRole } = this.props;
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_HOST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_GUEST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
      userDiffActionTaker: true,
    });
  };

  onPushPickUpAndDropOffEventGTM = (transaction, transitionName) => {
    let event = [];
    if (transitionName && transitionName.includes('request-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_SENT, EVENT_TRIP_PICKUP_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_CONFIRM, EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST];
    } else if (transitionName && transitionName.includes('request-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_SENT, EVENT_TRIP_DROPOFF_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_CONFIRM, EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST];
    }
    pushGTMTripEvent({
      props: this.props,
      event: event[0],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: event[1],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
      userDiffActionTaker: true,
    });
  };

  onCancelTripGTM = isAccepted => {
    const { transaction, transactionRole } = this.props;
    let events;
    if (isAccepted) {
      events =
        transactionRole === 'provider'
          ? [SERVER_EVENT_TRIP_HOST_CANCEL_GUEST, EVENT_TRIP_HOST_CANCEL_HOST]
          : [SERVER_EVENT_TRIP_GUEST_CANCEL_HOST, EVENT_TRIP_GUEST_CANCEL_GUEST];
      pushGTMTripEvent({
        props: this.props,
        event: events[0],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      pushGTMTripEvent({
        props: this.props,
        event: events[1],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
      });
    } else {
      events = [SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST, EVENT_BOOK_REQUEST_WITHDRAWN_GUEST];
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[0],
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[1],
        existTransaction: true,
      });
    }
  };

  shouldShowPickUpSection(value) {
    if (value.target) {
      this.setState({
        showPickUpSection: !this.state.showPickUpSection,
      });
    } else {
      this.setState({
        showPickUpSection: value,
      });
    }
  }

  shouldShowDropOffSection(value) {
    if (value.target) {
      this.setState({
        showDropOffSection: !this.state.showDropOffSection,
      });
    } else {
      this.setState({
        showDropOffSection: value,
      });
    }
  }

  setActivePhotoSection = photos => {
    const { exteriorPhotos } = this.state;
    const exteriorPhotosBool = photos[0].type === 'exteriorPhotos';
    const interiorPhotosBool = photos[0].type === 'interiorPhotos';
    const odometerPhotosBool = photos[0].type === 'odometerPhotos';
    const fuelGaugePhotosBool = photos[0].type === 'fuelGaugePhotos';
    const fuelReceiptPhotosBool = photos[0].type === 'fuelReceiptPhotos';
    const selfieWithIdBool = photos[0].type === 'selfieWithId';
    const pickupProviderPhotosBool = photos[0].type === 'pickupProviderPhoto';
    const index = photos[0].id;
    const currentActivePhotoSection = this.state.activePhotoSection;
    const totatalExteriorPhotos = exteriorPhotosBool && [...exteriorPhotos, ...photos];
    if (exteriorPhotosBool && totatalExteriorPhotos.length >= 4) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    if (
      (interiorPhotosBool ||
        odometerPhotosBool ||
        fuelGaugePhotosBool ||
        fuelReceiptPhotosBool ||
        selfieWithIdBool ||
        pickupProviderPhotosBool) &&
      photos.length >= 1
    ) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    this.setState({
      currentPhotos: [...this.state.currentPhotos, ...photos],
    });
  };

  checkLatLngParams = (lat, lng) => {
    return lat && !isNaN(lat) && lng && !isNaN(lng);
  };

  isShuStatusPresent = transaction => {
    return (
      transaction &&
      transaction.attributes &&
      transaction.attributes.metadata &&
      transaction.attributes.metadata['shuStatus1hour']
    );
  };

  initShuStatusLocation = transaction => {
    // Here we have to check if it is in hour or 15 minutes
    if (this.isShuStatusPresent(transaction)) {
      const shuStatus = transaction.attributes.metadata['shuStatus1hour'];
      const shuAddress = transaction.attributes.metadata['pickupSHULocation'];
      const { last_known_gps_latitude: gpsLat, last_known_gps_longitude: gpsLng } = shuStatus;
      this.setState({
        shuStatusLocation: {
          status: SHU_STATUS_DISPLAY_STATUS.SUCCESS,
          location: {
            lat: Number(gpsLat),
            lng: Number(gpsLng),
          },
          address: shuAddress,
        },
      });
    }
  };

  getLocationData = listing => {
    const getLocation = () => {
      return (
        (listing &&
          listing.attributes &&
          listing.attributes.publicData &&
          listing.attributes.publicData.location) ||
        {}
      );
    };

    const location = getLocation();
    const pc = location.postalCode || '';
    const address = location.address || '';
    return { pc, defaultAddress: address };
  };

  getAccurateGeoLocation = (transaction, listingForMap, geolocation) => {
    const { defaultAddress } = this.getLocationData(listingForMap);
    if (this.isShuStatusPresent(transaction)) {
      const shuStatus = transaction.attributes.metadata['shuStatus1hour'];
      const shuAddress = transaction.attributes.metadata['pickupSHULocation'];
      if (typeof shuStatus === 'object') {
        const { last_known_gps_latitude: gpsLat, last_known_gps_longitude: gpsLng } = shuStatus;
        if (this.checkLatLngParams(gpsLat, gpsLng)) {
          return {
            geolocation: {
              ...geolocation,
              lat: Number(gpsLat),
              lng: Number(gpsLng),
            },
            address: shuAddress || '',
          };
        }
      }
    }

    const { shuStatusLocation } = this.state;
    const {
      last_known_gps_latitude: sGpsLat,
      last_known_gps_longitude: sGpsLng,
    } = shuStatusLocation.location;
    const shuStateAddress = shuStatusLocation.address;
    if (this.checkLatLngParams(sGpsLat, sGpsLng)) {
      return {
        geolocation: {
          ...geolocation,
          lat: Number(sGpsLat),
          lng: Number(sGpsLng),
        },
        address: shuStateAddress || '',
      };
    }

    return { geolocation, address: defaultAddress };
  };

  getApproximateGeoLocation = (listingForMap, geolocation) => {
    const { defaultAddress } = this.getLocationData(listingForMap);
    let newAddress = (defaultAddress || '')
      .split(',')
      .filter(Boolean)
      .slice(-2)
      .map(word => {
        return word.trim();
      });
    newAddress = newAddress.join(', ');
    return {
      geolocation,
      address: newAddress,
    };
  };

  render() {
    const {
      cancelAllUpdateBookingTxsInProgress,
      bookingUpdateTxs,
      lastBookingUpdate,
      acceptUpdateBookingInProgress,
      cancelUpdateBookingInProgress,
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      acceptButtonName,
      declineButtonName,
      onTransit,
      transitInProgress,
      transitError,
      onSendMessage,
      ownListing,
      transactionWithoutRestore,
      acceptSaleGTM,
      declineSaleGTM,
      isMobileLayout,
      isTabletLayout,
      history,
      onUploadInteriorPhotoToMetadata,
      uploadInteriorPhotoProgress,
      uploadInteriorSuccess,
      onFetchCancelUpdateBooking,
      listingForMap,
      onAcceptUpdateBooking,
      onCancelUpdateBooking,
      onCancelAllUpdateBookings,
      isLimitedUser,
      onCancelUpdateBookingByAdmin,
      onAcceptUpdateBookingByAdmin,
      isCancelUpdateBookingByAdminPending,
      isAcceptUpdateBookingByAdminPending,
      getDistanceFromPickUp,
      distanceFromPickUp,
      userLocation,
      shouldShowPickUpSections,
      shouldShowDropOffSections,
      showNewPickUpSection,
      showNewDropOffSection,
      isLockboxActive,
      listing,
      lockbox,
      setShowViewDetails,
      exactAddress,
      exactGeoLocation,
      transitionMaybe,
      requirePickupLocation,
      requireDropoffLocation,
      isOlderVersion,
      isDistanceCharge,
      distanceKmPrice,
      excessReductionType,
      learnMoreForTolls,
      learnMoreForExcessReduction,
      learnMoreForDistance,
    } = this.props;



    const currentTransaction = ensureTransaction(transaction);
    const lastTransition = txLastTransition(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const timeZone = getDefaultTimeZoneOnBrowser(
      currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
    );
    const fuelType = currentListing.attributes.publicData.fuelType;
    const customerLocation = currentTransaction.attributes.protectedData.customerLocation;

    const photoObjects = get(transaction, 'attributes.protectedData.photoObjects', []);
    const flatPhotoObjects = photoObjects && photoObjects.flat();
    const pickUpOdometer = parseInt(get(flatPhotoObjects.find((photo) => get(photo, 'odometerPhotos', false) && get(photo, 'isPickUp', false)), "note", 0));
    const dropOffOdometer = parseInt(get(flatPhotoObjects.find((photo) => get(photo, 'odometerPhotos', false) && !get(photo, 'isPickUp', false)), "note", 0));

    const userImageURL =
      (currentUser &&
       currentUser.profileImage &&
       currentUser.profileImage.attributes &&
       currentUser.profileImage.attributes.variants &&
       currentUser.profileImage.attributes.variants["square-small"] &&
       currentUser.profileImage.attributes.variants["square-small"].url)
       || '';

    const customerAddress = customerLocation && customerLocation.selectedPlace;
    const listingId = currentListing.id.uuid;
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    const stateData = this.stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned);
    const currentUserRole = getUserTxRole(currentUser.id, transaction);
    const isInstantBooking = currentListing.attributes.publicData.instantBooking;
    const isDrivelahGo =
      currentTransaction.attributes.protectedData.isDrivelahGo ||
      currentListing.attributes.metadata.isDrivelahGo || false;

    const isHourlyBooking = get(currentTransaction, 'attributes.protectedData.hourlyBooking');
    const { displayStart } = get(currentTransaction, 'booking.attributes', {});
    const isTimeToShowTransitionPickup = moment().isSameOrAfter(
      moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes')
    );
    const isTimeToShowStartTripButton = moment()
      .tz(timeZone)
      .isSameOrAfter(
        moment(displayStart)
          .tz(timeZone)
          .add(-15, 'minutes')
      );
    const engineStart = get(currentTransaction, 'listing.attributes.publicData.engineStart', '');
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });
    const isUserLocationAvailable = userLocation && userLocation.lat !== null && userLocation.lng !== null;

    const currentChildTransaction = get(
      currentTransaction,
      'attributes.metadata.currentChildTransaction'
    );
    const nextCancelTransition = getNextCancelTransition(
      currentChildTransaction ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );

    const haveNextCancelTransit = nextCancelTransition.length > 0;

    const transactionForCancel = !isLongTerm
      ? transactionWithoutRestore
      : currentChildTransaction
      ? currentTransaction.currentChildTransaction
      : currentTransaction;

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
      otherUser,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = listingForMap.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    let modifiedGeoLocation = geolocation;
    let locationAddress = location.address || undefined;
    const currentTime = moment();
    const pickupTime = moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes');
    const showExactShuLocation = pickupTime.diff(currentTime, 'minutes') <= 60;
    const showFetchLiveLocationButton = pickupTime.diff(currentTime, 'minutes') <= 15 && !showExactShuLocation
    const isTripYetToStart = true;

    if (isDrivelahGo) {
      if (showExactShuLocation) {
        const exactLocation = this.getAccurateGeoLocation(
          currentTransaction,
          listingForMap,
          geolocation
        );
        modifiedGeoLocation = exactLocation.geolocation;
        locationAddress = exactLocation.address;
      } else {
        const approxLocation = this.getApproximateGeoLocation(listingForMap, geolocation);
        modifiedGeoLocation = approxLocation.geolocation;
        locationAddress = approxLocation.address;
      }
    }

    const listingRegistrationNumber = currentListing.attributes.publicData.license_plate_number;

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const updateBookingButtons = (
      <UpdateBookingButtonsMaybe
        showButtons={stateData.showBookingButtons}
        transaction={currentTransaction}
        acceptInProgress={acceptUpdateBookingInProgress}
        declineInProgress={cancelUpdateBookingInProgress}
        onAcceptUpdateBooking={onAcceptUpdateBooking}
        onCancelUpdateBooking={onCancelUpdateBooking}
        bookingUpdateTxs={bookingUpdateTxs}
        intl={intl}
        history={history}
        isLimitedUser={isLimitedUser}
        onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
        onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
        isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
        isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
      />
    );

    const isDeliveryTransaction = get(currentTransaction, 'attributes.protectedData.isDelivery');

    const classes = classNames(rootClassName || css.root, className);
    const actionButtonClasses = classNames(css.actionButtons);
    const nextInteractableTransitions = getNextInteractableTransition(
      isFinishLongTerm ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextTransit = nextInteractableTransitions.length > 0;

    const validTripPhotos =
      currentTransaction.attributes.protectedData.photoObjects &&
      currentTransaction.attributes.protectedData.photoObjects.length > 0;
    const metaPhotoObject =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.photoObjects;
    const interiorPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['interiorPhotos']).length > 0)
      : [];
    const odometerPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['odometerPhotos']).length > 0)
      : [];
    const fuelGaugePhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['fuelGaugePhotos']).length > 0)
      : [];
    const tripPhotosProtectedData = validTripPhotos
      ? currentTransaction.attributes.protectedData.photoObjects
      : [];
    const allTripPhotos = [...tripPhotosProtectedData];
    const confirmTransitions = [
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_CONFIRM_DROP_OFF,
      TRANSITION_REQUEST_DROP_OFF_DLGO,
    ];

    const needInputOdometer =
      get(currentTransaction, 'attributes.protectedData.payForFuel', false) &&
      [...pickupTransitions, ...dropoffTransitions].includes(nextInteractableTransitions[0]);

    const inputOdometerInvalid =
      !confirmTransitions.includes(nextInteractableTransitions[0]) &&
      needInputOdometer &&
      (isEmpty(this.state.numberOdometer) || isNaN(this.state.numberOdometer));

    const isShowUploadModal =
      (((txIWaitingRequestPickup(currentTransaction) ||
        txIWaitingRequestPickupLTF(currentTransaction)) &&
        isCustomer) ||
        ((txIsBeingPickedUp(currentTransaction) || txIsBeingPickedUpLTF(currentTransaction)) &&
          isProvider) ||
        ((txIWaitingRequestDropoff(currentTransaction) ||
          txIWaitingRequestDropoffLTL(
            currentTransaction && currentTransaction.currentChildTransaction
          )) &&
          isCustomer) ||
        txIsBeingDropOff(currentTransaction) ||
        (txIsBeingDropOffLTL(currentTransaction && currentTransaction.currentChildTransaction) &&
          isProvider)) &&
      haveNextTransit;

    const needDirection = isDrivelahGo ? showExactShuLocation : true;
    const isPickUp =
      txIWaitingRequestPickup(currentTransaction) ||
      txIsBeingPickedUp(currentTransaction) ||
      txIWaitingRequestPickupLTF(currentTransaction) ||
      txIsBeingPickedUpLTF(currentTransaction) ||
      txIsReturnedBackBeforePickUp(currentTransaction);

    let mapSectionPartUnverified = null;
    if (!txInVerificationProcess(transaction) && geolocation) {
      if (isCustomer) {
        mapSectionPartUnverified = (
          <StartTripAddressLinkMaybe
            rootClassName={css.addressMobile}
            location={isProvider ? customerAddress : exactAddress}
            geolocation={exactGeoLocation}
            showAddress={stateData.showAddress}
            isProvider={isProvider}
            needDirection={needDirection}
            showExactShuLocation={showExactShuLocation}
            isPickUp={isPickUp}
          />
        );
      }
    }

    const parkingType = transaction.listing.attributes.publicData.parkingType
    const parkingInfo = isPickUp ? transaction.listing.attributes.publicData.parkingInfo :
      parkingType === 'street' ?
        'Ensure the car is parked in a free parking spot within 500m of the provided address.' :
        'Return the car to the exact spot where you picked it up.'
    console.log('transaction parking', transaction)
    const mapSection = (
      <div className={!txInVerificationProcess(transaction) ? css.mapContainer : null}>
        { isCustomer && (<>
          {mapSectionPartUnverified}
          { !isCustomer && <BookingStatus
            lastBookingUpdate={lastBookingUpdate}
            currentUserRole={currentUserRole}
            transaction={transaction}
            otherUser={otherUser}
            shouldShowPickUpSection={this.shouldShowPickUpSection}
            shouldShowDropOffSection={this.shouldShowDropOffSection}
            transitionMaybe={transitionMaybe}
            timeZone={timeZone}
            isCustomer={isCustomer}
            isProvider={isProvider}
            providerName={authorDisplayName}
            customerName={customerDisplayName}
            isLongTerm={isLongTerm}
            isDrivelahGo={isDrivelahGo}
            openModalReview={() => this.setState({ isReviewModalOpen: true })}
            isMobileLayout={isMobileLayout}
            openDisputeModal={() => this.setState({ showDisputeModal: true })}
            bookingUpdateTxs={bookingUpdateTxs}
            intl={intl}
          />}

          { isCustomer && <div className={`${css.instructionsWrapper}`}>
              <div>
                <h5>Parking</h5>
                <span>{parkingInfo}</span>
              </div>
            </div>
          }
        </>) }

        {isCustomer &&
        !txInVerificationProcess(transaction) &&
        currentCustomer.attributes &&
        currentCustomer.attributes.profile &&
        currentCustomer.attributes.profile.publicData &&
        currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
          'confirmed' ? (
          <StartTripListingMap
            geolocation={exactGeoLocation}
            exactAddress={exactAddress}
            listing={currentListing}
            showAddress={stateData.showAddress}
            className={css.locationTripMap}
            dynamicMarkerIconURL={userImageURL}
            listingForMap={listingForMap}
            isCustomer={isCustomer}
            getDistanceFromPickUp={getDistanceFromPickUp}
            distanceFromPickUp={distanceFromPickUp}
            userLocation={userLocation}
            isPickUp = {isPickUp}
          />
        ) : null}
        {isProvider && !txInVerificationProcess(transaction) ? (
          <CustomerMap
            geolocation={modifiedGeoLocation}
            transaction={currentTransaction}
            showAddress={stateData.showAddress}
          />
        ) : null}
         { !isCustomer && mapSectionPartUnverified}
      </div>
    );

    const chatSection = (
      <div className={isProvider ? css.chatContainerProvider : css.chatContainer}>
        <FeedSection
          onOpenShowImgModal={url => this.setState({ showImgModal: true, showImgModalUrl: url })}
          rootClassName={css.feedContainer}
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          fetchMessagesError={fetchMessagesError}
          fetchMessagesInProgress={fetchMessagesInProgress}
          initialMessageFailed={initialMessageFailed}
          messages={messages}
          oldestMessagePageFetched={oldestMessagePageFetched}
          onOpenReviewModal={this.onOpenReviewModal}
          onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
          totalMessagePages={totalMessagePages}
          isFinishLongTerm={isFinishLongTerm}
        />
        <div id="headMessageForm" className={css.headMessageForm} />
        {showSendMessageForm ? (
          <SendMessageForm
            currentTransaction={currentTransaction}
            currentUser={currentUser}
            messages={messages}
            formId={this.sendMessageFormName}
            rootClassName={css.sendMessageForm}
            messagePlaceholder={sendMessagePlaceholder}
            inProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            onFocus={this.onSendMessageFormFocus}
            onBlur={this.onSendMessageFormBlur}
            onSubmit={this.onMessageSubmit}
            submitButtonId={TRIP_SEND_MESSAGE_BUTTON_ID}
          />
        ) : (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
        )}
      </div>
    );

    const photoSectionListCustomer = [
      {
        id: 0,
        title: 'Exterior of the car photos',
        description:
          'Document the condition of the car from the outside. Minimum 4 photos, one from each side.',
        data: 'exteriorPhotos',
      },
      {
        id: 1,
        title: 'Interior of the car photos',
        description: 'Document the condition of the car from the inside.',
        data: 'interiorPhotos',
      },
      {
        id: 2,
        title: 'Odometer Photo',
        description: 'Ensure odometer reading is clearly visible.',
        data: 'odometerPhotos',
      },
      {
        id: 3,
        title: 'Fuel Gauge Photo',
        description: 'Ensure fuel gauge value is clearly visible and readable.',
        data: 'fuelGaugePhotos',
      },
    ];

    const photoSectionListProvider = [
      {
        id: 0,
        title: 'Upload photos (optional)',
        description:
          'You can choose to add photos to counter validate and avoid disputes at the end. Not mandatory.',
        data: 'pickupProviderPhoto',
        isEdit: true,
      },
    ];


    const photoSectionListDropOff = isCustomer
      ? [...photoSectionListCustomer]
      : [...photoSectionListProvider];

    if (isCustomer && isDrivelahGo) {
      photoSectionListDropOff.push({
        id: 4,
        title: 'Fuel Receipt',
        description:
          'If you have topped up the fuel add receipts for that. If no receipts are added we will charge you for fuel on basis of the distance traveled.',
        data: 'fuelReceiptPhotos',
      });
    }

    const isDropOff =
      txIsBeingDropOff(currentTransaction) ||
      txIWaitingRequestDropoff(currentTransaction) ||
      txIWaitingRequestDropoffLTL(currentTransaction) ||
      txIsBeingDropOffLTL(currentTransaction);

    const isPickUpInterior =
      txIsPickedUp(currentTransaction) ||
      txAfterCancelUpdateTrip(currentTransaction) ||
      (txIsPickedUpLTF(currentTransaction) && isDrivelahGo);

    const onNoteChange = (note, type) => {
      if (this.state[type].length > 0) {
        const photoWithNote = [...this.state[type]];
        photoWithNote.map(i => {
          i.note = note;
        });

        this.setState({ [type]: photoWithNote });
      }
    };

   const exteriorPhotoFun = (data) => {
      this.setState((prevState) => ({
        exteriorPhotos: [...prevState.exteriorPhotos, ...data]
      }));
    }

    const isShowPhotoSection = this.state.showPickUpSection || this.state.showDropOffSection;

    const isShowCancelLinkCustomer =
      lastTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||
      lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED ||
      ((lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
        isTripYetToStart);
    const isShowAddonsLinkCustomer =
      (lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
      !isTimeToShowTransitionPickup;

    const isShuEnabledCar =
      currentTransaction.listing &&
      currentTransaction.listing.attributes.metadata &&
      currentTransaction.listing.attributes.metadata.isDrivelahGo;

    const allTransitions = get(currentTransaction, 'attributes.transitions');
    const isPickupRequested =
      allTransitions &&
      allTransitions.find(
        i =>
          i.transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
          i.transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE
      );

    const isBookingCompleted =
      transaction.booking &&
      moment()
        .tz(timeZone)
        .isSameOrAfter(
          moment(transaction.booking.attributes.displayEnd)
            .add(6, 'hours')
            .tz(timeZone)
        );

    return (
      <div className={classes}>
      { !this.state.showStartTripStep ?  (
        <div className={css.container}>
        <div className={css.txInfo}>
          {!isShowPhotoSection && (
            <StartTripPanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={listingId}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              showDropOffSection = {this.state.showDropOffSection}
              shouldShowPickUpSection = {shouldShowPickUpSections}
              shouldShowDropOffSection = {shouldShowDropOffSections}
              isPickUp = {isPickUp}
              setShowViewDetails={setShowViewDetails}
              toggleHelpModal={() => this.setState({ showCancelHelpModal: true })}
            />
          )}

          {!isShowPhotoSection && isProvider && chatSection}
          {!isShowPhotoSection &&
            lastTransition !== TRANSITION_ENQUIRE &&
            ((isDeliveryTransaction && isProvider) || isCustomer) &&
            mapSection}

          {!isShowPhotoSection && isTabletLayout && isCustomer && (
              <div className={css.mobileInfoContainer}>
                {!isShowPhotoSection && isTabletLayout  ? (
                  <TripInfo intl={intl} transaction={transaction} registrationNumber = {listingRegistrationNumber} fuelType={fuelType} listing={currentListing} isPickUp={isPickUp} mileageToShowText={this.props.mileageToShowText} isDrivelahGo={isDrivelahGo} isLockboxCar={lockbox}
                            isOlderVersion={isOlderVersion}
                            isDistanceCharge={isDistanceCharge}
                            distanceKmPrice={distanceKmPrice}
                            excessReductionType={excessReductionType}
                            learnMoreForTolls={learnMoreForTolls}
                            learnMoreForExcessReduction={learnMoreForExcessReduction}
                            learnMoreForDistance={learnMoreForDistance}
                  />
                ) : null}
              </div>
            )
          }

          {!isShowPhotoSection &&
            isCustomer &&
            !(
              txIsUnverifiedRequested(transaction) ||
              txIsUnverifiedInstantRequested(transaction) ||
              txIsUnverifiedSubmittedInstant(transaction) ||
              txIsUnverifiedSubmitted(transaction)
            ) && isPickUp &&
            chatSection}

        </div>

        {!isShowPhotoSection && (
          <div className={css.asideDesktop}>
            { isCustomer && (
              <>
                <div className={css.helpBtn}>
                  <button className={css.needHelpBtn} onClick={() => this.setState({ showCancelHelpModal: true })}> <img src={helpImg} alt='#' />Need help?</button>
                </div>
                <TripInfo intl={intl} registrationNumber = {listingRegistrationNumber} fuelType={fuelType} listing={currentListing} transaction={transaction} isPickUp={isPickUp} mileageToShowText={this.props.mileageToShowText} isDrivelahGo={isDrivelahGo} isLockboxCar={lockbox}
                          isOlderVersion={isOlderVersion}
                          isDistanceCharge={isDistanceCharge}
                          distanceKmPrice={distanceKmPrice}
                          excessReductionType={excessReductionType}
                          learnMoreForTolls={learnMoreForTolls}
                          learnMoreForExcessReduction={learnMoreForExcessReduction}
                          learnMoreForDistance={learnMoreForDistance}
                />
              </>
            )}
          </div>
        )}
        {updateBookingButtons}
        <div className={css.instructionSliderMobile}>
         <TripInstructionsSlider currentUser={currentUser} />
        </div>
         { <ButtonsSection
            declineInProgress={declineInProgress}
            declineButtonName={declineButtonName}
            isPickupRequested={isPickupRequested}
            transaction={transaction}
            acceptInProgress={acceptInProgress}
            acceptSaleError={acceptSaleError}
            declineSaleError={declineSaleError}
            acceptButtonName={acceptButtonName}
            currentUser={currentUser}
            onDeclineSale={onDeclineSale}
            declineSaleGTM={declineSaleGTM}
            onAcceptSale={onAcceptSale}
            acceptSaleGTM={acceptSaleGTM}
            currentPhotos={this.state.currentPhotos}
            activePhotoSection={this.state.activePhotoSection}
            exteriorPhotos={this.state.exteriorPhotos}
            selfieWithId={this.state.selfieWithId}
            interiorPhotos={this.state.interiorPhotos}
            odometerPhotos={this.state.odometerPhotos}
            fuelGaugePhotos={this.state.fuelGaugePhotos}
            fuelReceiptPhotos={this.state.fuelReceiptPhotos}
            pickupProviderPhoto={this.state.pickupProviderPhoto}
            transitionMaybe={transitionMaybe}
            onTransit={onTransit}
            showSaleButtons={stateData.showSaleButtons}
            showPickUpSection={this.state.showPickUpSection}
            showDropOffSection={this.state.showDropOffSection}
            transitInProgress={transitInProgress}
            inputOdometerInvalid={inputOdometerInvalid}
            isShuEnabledCar={isShuEnabledCar}
            isProvider={isProvider}
            isCustomer={isCustomer}
            allTripPhotos={allTripPhotos}
            isPickUp={isPickUp}
            isDropOff={isDropOff}
            isDrivelahGo={isDrivelahGo}
            onPushEventGTMPickUpDropOff={this.onPushPickUpAndDropOffEventGTM}
            ownListing={ownListing}
            onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
            interiorPhotoDlGo={interiorPhotoDlGo}
            odometerPhotoDlGo={odometerPhotoDlGo}
            fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
            uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
            uploadInteriorSuccess={uploadInteriorSuccess}
            shouldShowPickUpSection={this.shouldShowPickUpSection}
            shouldShowDropOffSection={this.shouldShowDropOffSection}
            distanceFromPickUp={distanceFromPickUp}
            showStartTripButton = {true}
            isTimeToShowStartTripButton={isTimeToShowStartTripButton}
            showStartTripStep={this.showStartTripStep}
            requirePickupLocation={requirePickupLocation}
            requireDropoffLocation={requireDropoffLocation}
            isUserLocationAvailable={isUserLocationAvailable}
            timeZone={timeZone}
         />
         }
         </div> ) :
         <PickupAndDropPage
            findDeviceByType = {this.props.findDeviceByType}
            transitInProgress={transitInProgress}
            showPickUpSection={showNewPickUpSection}
            declineInProgress={declineInProgress}
            declineButtonName={declineButtonName}
            isPickupRequested={isPickupRequested}
            transaction={transaction}
            acceptInProgress={acceptInProgress}
            acceptSaleError={acceptSaleError}
            declineSaleError={declineSaleError}
            acceptButtonName={acceptButtonName}
            currentUser={currentUser}
            onDeclineSale={onDeclineSale}
            declineSaleGTM={declineSaleGTM}
            onAcceptSale={onAcceptSale}
            acceptSaleGTM={acceptSaleGTM}
            currentPhotos={this.state.currentPhotos}
            activePhotoSection={this.state.activePhotoSection}
            exteriorPhotos={this.state.exteriorPhotos}
            selfieWithId={this.state.selfieWithId}
            interiorPhotos={this.state.interiorPhotos}
            odometerPhotos={this.state.odometerPhotos}
            fuelGaugePhotos={this.state.fuelGaugePhotos}
            fuelReceiptPhotos={this.state.fuelReceiptPhotos}
            pickupProviderPhoto={this.state.pickupProviderPhoto}
            transitionMaybe={transitionMaybe}
            onTransit={onTransit}
            showSaleButtons={stateData.showSaleButtons}
            showDropOffSection={showNewDropOffSection}
            inputOdometerInvalid={inputOdometerInvalid}
            isShuEnabledCar={isShuEnabledCar}
            isProvider={isProvider}
            isCustomer={isCustomer}
            allTripPhotos={allTripPhotos}
            isPickUp={isPickUp}
            isDropOff={isDropOff}
            isDrivelahGo={isDrivelahGo}
            onPushEventGTMPickUpDropOff={this.onPushPickUpAndDropOffEventGTM}
            ownListing={ownListing}
            onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
            interiorPhotoDlGo={interiorPhotoDlGo}
            odometerPhotoDlGo={odometerPhotoDlGo}
            fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
            uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
            uploadInteriorSuccess={uploadInteriorSuccess}
            shouldShowPickUpSection={shouldShowPickUpSections}
            shouldShowDropOffSection={shouldShowDropOffSections}
            distanceFromPickUp={distanceFromPickUp}
            showStartTripButton = {true}
            showStartTripStep={this.showStartTripStep}
            showStartTripPanel={() =>this.setState({showStartTripStep : false})}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection={this.setActivePhotoSection}
            clearCurrentPhotos = {() => this.setState({currentPhotos: []})}
            timeZone={timeZone}
            engineStart={engineStart}
            dropOffOdometer={dropOffOdometer}
            pickUpOdometer={pickUpOdometer}
            exteriorPhotoFun = {exteriorPhotoFun}
            isLockboxActive = {isLockboxActive}
            listing = {listing}
            lockbox = {lockbox}
            isOlderVersion={isOlderVersion}
         />
      }
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          buttonId={TRIP_PUBLISH_REVIEW_BUTTON_ID}
        />
        <ModalCancel
          cancelAllUpdateBookingTxsInProgress={cancelAllUpdateBookingTxsInProgress}
          rootClassName={actionButtonClasses}
          canShowButtons={haveNextCancelTransit}
          transaction={transactionForCancel}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          transitionNames={nextCancelTransition}
          openCancelPolicyModal={() => this.setState({ isCancelPolicyOpen: true })}
          isCustomer={isCustomer}
          isProvider={isProvider}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          showCancelModal={this.state.showCancelModal}
          onClose={() => this.setState({ showCancelModal: false })}
          isInstantBooking={isInstantBooking}
          onCancelTripGTM={this.onCancelTripGTM}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isAccepted={txIsAccepted(currentTransaction)}
          isLongTerm={isLongTerm}
          currentChildTransaction={currentChildTransaction}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
          onCancelAllUpdateBookings={onCancelAllUpdateBookings}
        />
        <ModalHelp
          onClose={() => this.setState({ showCancelHelpModal: false })}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isOpen={this.state.showCancelHelpModal}
        />
        <ModalCancelPolicy
          containerClassName={css.missingInformationModal}
          onManageDisableScrolling={onManageDisableScrolling}
          transactionRole={transactionRole}
          showPolicyModal={this.state.isCancelPolicyOpen}
          onClose={() => this.setState({ isCancelPolicyOpen: false })}
          isLongTerm={isLongTerm}
        />
        <ModalReasonDispute
          isOpen={this.state.showDisputeModal}
          onClose={() => this.setState({ showDisputeModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          transaction={currentTransaction}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          onSendMessage={onSendMessage}
          reasonDispute={this.state.reasonDispute}
          reasonDisputePhotoUrls={this.state.reasonDisputePhotoUrls}
          onChoosePhoto={fileUrl => this.handleChooseDisputePhoto(fileUrl)}
          tripPhotos={[
            ...allTripPhotos,
            ...(isShowUploadModal ? this.state.tripPhotos : []),
          ].reduce((acc, val) => acc.concat(val), [])}
          onReasonDisputeChange={value => this.setState({ reasonDispute: value })}
          onPushDisputeEventGTM={this.onPushDisputeEventGTM}
          submitButtonId={TRIP_SUBMIT_DISPUTE_BUTTON_ID}
        />
      </div>

    );
  }

  stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned, isDelivery) {
    const lastTransition = transaction.attributes.lastTransition;
    if (txIsEnquired(transaction)) {
      return {
        headingState: HEADING_ENQUIRED,
        showBookingPanel: false,
      };
    } else if (txIsPaymentPending(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsPaymentExpired(transaction)) {
      return {
        headingState: HEADING_PAYMENT_EXPIRED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsWithdrawnByAdmin(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN_BY_ADMIN,
        showDetailCardHeadings: true,
      };
    } else if (txIsWithdrawn(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsRequested(transaction) || txIsRequestedLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showSaleButtons: isProvider && !isCustomerBanned,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)
    ) {
      return {
        headingState: HEADING_DECLINED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP)
    ) {
      return {
        headingState: HEADING_ACCEPTED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAccepted(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (
      txIWaitingRequestPickup(transaction) ||
      txIWaitingRequestPickupLTF(transaction) ||
      txIsBeingDropOff(transaction) ||
      txIsBeingDropOffLTL(transaction)
    ) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsBeingPickedUp(transaction) || txIsBeingPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsDeclined(transaction) || txIsDeclinedLTF(transaction)) {
      return {
        headingState: HEADING_DECLINED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsCanceled(transaction)) {
      return {
        headingState: HEADING_CANCELED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txHasBeenDelivered(transaction)) {
      return {
        headingState: HEADING_DELIVERED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedInstantRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedSubmitted(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else {
      return { headingState: 'unknown' };
    }
  }
}

StartTripPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitError: null,
  keyRulesConfig: config.custom.keyRules,
  nextTransitions: null,
};

StartTripPanelComponent.propTypes = {
  cancelAllUpdateBookingTxsInProgress: bool,
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  //transit props
  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,

  // from injectIntl
  intl: intlShape,

  keyRulesConfig: PropTypes.array
};

const StartTripPanel = injectIntl(StartTripPanelComponent);

export default StartTripPanel;
