import React, { useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';

import css from './TripPanel.css';
import { flatten } from 'lodash';
import classNames from 'classnames';
import tick from '../../assets/tripPage/tick.svg';
import disabledTick from '../../assets/tripPage/tick-disabled.svg';
import moment from 'moment';
import DateInputComponent from '../FieldDateInput/DateInput';

const DropOffSection = props => {
  const {
    shouldShowDropOffSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    isLimitedUser,
    removePhoto,
    isDropOff,
    pickUpOdometer,
    isAdminAction,
    dropOffSentAtByAdmin,
    customDropOffReadingByAdmin,
    setDropOffSentAt,
    dropoffSentAt,
    timeZone
  } = props;

  const flatTripPhotos =  [
    ...allTripPhotos,
  ];

  const isShowUpload = (isLimitedUser && isPickUp && isDropOff) || (!isDropOff && !isPickUp)

  const filterTripPhotos = flatten(flatTripPhotos).filter(i => !i.isPickUp);
  const classes = classNames(css.section_root);
  const holderClassName= dropOffSentAtByAdmin !== null ? css.activeSection : css.inactiveSection
  const [selectedDate, setSelectedDate] = useState(
    dropOffSentAtByAdmin
      ? moment.tz(dropOffSentAtByAdmin, timeZone).toDate()
      : dropoffSentAt
        ? moment.tz(dropoffSentAt, timeZone).toDate()
        : null
  );

  const initialHour = selectedDate ? moment(selectedDate).format('hh') : '';
  const initialMinute = selectedDate ? moment(selectedDate).format('mm') : '';
  const initialMeridiem = selectedDate ? moment(selectedDate).format('A') : 'AM';

  const [hour, setHour] = useState(initialHour);
  const [minute, setMinute] = useState(initialMinute);
  const [meridiem, setMeridiem] = useState(initialMeridiem);

  const handleDateChange = (date) => {
    if (!date) {
      setSelectedDate(selectedDate); // Reset to the last valid date
      return;
    }
    setSelectedDate(date);
    updateDropOffSentAt(date, hour, minute, meridiem);
  };

  const handleTimeChange = (updatedHour, updatedMinute, updatedMeridiem) => {
    setHour(updatedHour || hour);
    setMinute(updatedMinute || minute);
    setMeridiem(updatedMeridiem || meridiem);
    updateDropOffSentAt(selectedDate, updatedHour || hour, updatedMinute || minute, updatedMeridiem || meridiem);
  };

  const updateDropOffSentAt = (date, updatedHour, updatedMinute, updatedMeridiem) => {
    if (date && updatedHour && updatedMinute && updatedMeridiem) {
      const timeString = `${updatedHour}:${updatedMinute} ${updatedMeridiem}`;
      const combinedDateTime = moment.tz(
        `${moment(date).format('YYYY-MM-DD')} ${timeString}`,
        'YYYY-MM-DD hh:mm A',
        timeZone
      );
      setDropOffSentAt(combinedDateTime.toISOString());
    }
  };

  const generateHourOptions = () =>
    Array.from({ length: 12 }, (_, i) => `${i + 1}`.padStart(2, '0'));

  const generateMinuteOptions = () =>
    Array.from({ length: 60 }, (_, i) => `${i}`.padStart(2, '0'));

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={shouldShowDropOffSection}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isLimitedUser ? 'Dropoff as Admin' : isCustomer ? 'Send drop-off request' : 'Confirm drop-off request'}
        </h2>
      </div>
      {isLimitedUser && (
        <div className={classNames(classes, holderClassName)}>
          <div className={classNames(css.sectionPhotoHolder)}>
            <div className={css.dropoffTimeTickContainer}>
              <img src={!dropOffSentAtByAdmin && !dropoffSentAt ? disabledTick : tick} alt="tick" className={css.tick} />
            </div>

            <div>
              <h2 className={css.photoTitle}>Select drop-off time</h2>
              <p className={css.photoDescription}>Select the date and time when the guest dropped of the car. Please ensure you input the date and time in {timeZone}. Use a convertor if needed to get the right time in this timezone.</p>
              <div className={css.dateTimePicker}>
                <DateInputComponent
                  id="dropoff_date_picker"
                  value={{ date: selectedDate }}
                  onChange={({ date }) => handleDateChange(date)}
                  isOutsideRange={() => false}
                  placeholderText="Select date"
                  showClearDate={false}
                  isDropoffTime={true}
                  disabled={!isDropOff}
                />

                <div className={css.timeContainer}>
                  <select
                    value={hour}
                    onChange={e => handleTimeChange(e.target.value, null, null)}
                    disabled={!isDropOff}
                    className={css.timeInput}
                  >
                    <option value="" disabled>
                      Hour
                    </option>
                    {generateHourOptions().map(opt => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </select>

                  <select
                    value={minute}
                    onChange={e => handleTimeChange(null, e.target.value, null)}
                    disabled={!isDropOff}
                    className={css.timeInput}
                  >
                    <option value="" disabled>
                      Min
                    </option>
                    {generateMinuteOptions().map(opt => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </select>

                  <select
                    value={meridiem}
                    onChange={e => handleTimeChange(null, null, e.target.value)}
                    disabled={!isDropOff}
                    className={css.timeInput}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {photoSectionList.map(i => {
        return (
          <SectionCarPhotos
            id={i.id}
            key={i.id}
            setActivePhotoSection={setActivePhotoSection}
            // onClick={e => setActivePhotoSection(e, i.id)}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer && !isLimitedUser}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={false}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            inboxLabel={i.inboxLabel}
            inboxPlaceholder={i.inboxPlaceholder}
            onNoteChange={onNoteChange}
            removePhoto={removePhoto}
            isShowUpload={isShowUpload}
            pickUpOdometer={pickUpOdometer}
            isAdminAction={isAdminAction}
            customDropOffReadingByAdmin={customDropOffReadingByAdmin}
          />
        );
      })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionListCustomer.map(i => {
          return (
            <SectionCarPhotosOnlyView
              key={i.id}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={isPickUp}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          );
        })}
    </div>
  );
};

export default DropOffSection;
