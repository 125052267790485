import React, { useEffect, useRef, useState } from 'react';
import css from './ListingPageGo.css';
import config from '../../../config';
import { PropertyList } from '../../../components';
import { FormattedMessage } from 'react-intl';

const SectionDescriptionMaybe = props => {
  const { authorDisplayName, listing, description } = props;
  const { brandName, transmissions, fuelType, peopleNumber: seats, drivenKm } =
    listing.attributes.publicData || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const descriptionRef = useRef(null);
  const MAX_LINES = 5;

  const seatsLabel =
    typeof seats === 'string' || typeof seats === 'number'
      ? `${seats || '0'} Seats`
      : seats && seats.length > 0
      ? `${seats ? seats[0] : '0'} - ${seats ? seats[1] : '0'} Seats`
      : '';

  let options = [
    ...config.custom.brands,
    ...config.custom.transmissions,
    ...config.custom.fuelTypes,
    { key: 'numberSeats', label: seatsLabel },
    ...config.custom.drivenKm,
  ];

  const selectedOptions = [
    brandName || '',
    ...(transmissions || ''),
    fuelType || '',
    'numberSeats',
    drivenKm || '',
  ];

  options = options.filter(option => {
    return selectedOptions.filter(select => select === option.key).length > 0;
  });

  // Manage car owner's name
  const manageOwnerName = name => {
    const getLastLetter = name.slice(-1);
    if (getLastLetter.toLowerCase() === 'h') {
      const getLast2Letter = name.slice(-2);
      if (getLast2Letter.toLowerCase() === 'sh') {
        return name + "'";
      }
    } else {
      if (getLastLetter.toLowerCase() === 's') {
        return name + "'";
      }
    }
    return name + "'s";
  };

  const toggleDescription = () => setIsExpanded(!isExpanded);

    useEffect(() => {
      if (descriptionRef.current) {
        const lineHeight = parseFloat(
          window.getComputedStyle(descriptionRef.current).lineHeight
        );
        const maxHeight = lineHeight * MAX_LINES;
  
        if (descriptionRef.current.scrollHeight > maxHeight) {
          setIsTruncated(true);
        } else {
          setIsTruncated(false); 
        }
      }
    }, [description]);

  return (
    <div className={css.section}>
      <div className={css.sectionTitle}>
        <FormattedMessage
          id="ListingPageGo.SectionDescriptionMaybe.title"
          values={{ authorDisplayName: manageOwnerName(authorDisplayName) }}
        />
      </div>
      {description && (
        <div>
          <p
            ref={descriptionRef}
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: isExpanded ? 'unset' : MAX_LINES,
              overflow: isExpanded ? 'visible' : 'hidden',
              whiteSpace: 'pre-wrap',
            }}
          >
            {description}
          </p>

          {isTruncated && (
            <button
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: '#00a3ad',
                textDecoration: 'underline',
                padding: 0,
                marginBottom: '30px',
              }}
              onClick={toggleDescription}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </button>
          )}
        </div>
      )}
      <PropertyList id="descriptionMaybe" list={options} />
    </div>
  );
};

export default SectionDescriptionMaybe;